import React from 'react'
import ftnAktiaAuth from 'assets/images/SCAs/ftn-aktia-auth.svg'
import ftnAlandsbankenAuth from 'assets/images/SCAs/ftn-alandsbanken-auth.svg'
import ftnDanskebankAuth from 'assets/images/SCAs/ftn-danskebank-auth.svg'
import ftnHandelsbankenAuth from 'assets/images/SCAs/ftn-handelsbanken-auth.svg'
import ftnLocaltapiolaAuth from 'assets/images/SCAs/ftn-localtapiola-auth.svg'
import ftnNordeaAuth from 'assets/images/SCAs/ftn-nordea-auth.svg'
import ftnOmaspAuth from 'assets/images/SCAs/ftn-omasp-auth.svg'
import ftnOpAuth from 'assets/images/SCAs/ftn-op-auth.svg'
import ftnPopAuth from 'assets/images/SCAs/ftn-pop-auth.svg'
import ftnSpAuth from 'assets/images/SCAs/ftn-sp-auth.svg'
import ftnSpankkiAuth from 'assets/images/SCAs/ftn-spankki-auth.svg'
import mobiilivarmenneFtn from 'assets/images/SCAs/mobiilivarmenne-ftn.svg'

interface Props {
  methodName: string
  onClick: () => void
  disabled?: boolean
}

const SignicatTile = (props: Props) => {
  const { methodName, onClick, disabled } = props

  return (
    <div
      className={`sca-item ${disabled && 'disabled'}`}
      onClick={() => !disabled && onClick()}
    >
      <img src={getImageByName(methodName)} alt={methodName} />
    </div>
  )
}

const getImageByName = (name: string) => {
  switch (name) {
    case 'ftn-aktia-auth':
      return ftnAktiaAuth
    case 'ftn-alandsbanken-auth':
      return ftnAlandsbankenAuth
    case 'ftn-danskebank-auth':
      return ftnDanskebankAuth
    case 'ftn-handelsbanken-auth':
      return ftnHandelsbankenAuth
    case 'ftn-localtapiola-auth':
      return ftnLocaltapiolaAuth
    case 'ftn-nordea-auth':
      return ftnNordeaAuth
    case 'ftn-omasp-auth':
      return ftnOmaspAuth
    case 'ftn-op-auth':
      return ftnOpAuth
    case 'ftn-pop-auth':
      return ftnPopAuth
    case 'ftn-sp-auth':
      return ftnSpAuth
    case 'ftn-spankki-auth':
      return ftnSpankkiAuth
    case 'mobiilivarmenne-ftn':
      return mobiilivarmenneFtn
    default:
      return ftnAktiaAuth
  }
}

SignicatTile.defaultProps = {
  disabled: false,
}

export default SignicatTile
