import React from 'react'
import { State } from '../../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'
import En from './En'
import Fi from './Fi'
import Se from './Se'

interface Props extends MappedStateModel {}

const components = {
  en: En,
  de: En,
  fi: Fi,
  se: Se,
}

const PirvacyPolicy = (props: Props) => {
  const { language } = props
  const Presentation = components[language]
  return <Presentation />
}

interface MappedStateModel {
  language: string
}

const mapStateToProps = (state: State): MappedStateModel => ({
  language: state.user.profile.data.language,
})

const enhancer = compose<any>(
  connect<MappedStateModel, any, any, any>(mapStateToProps)
)

export default enhancer(PirvacyPolicy)
