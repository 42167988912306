import React from 'react'
import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

interface IProps {
  url: string
  lable: string
}

const NavMenuItem: React.FunctionComponent<IProps> = ({ url, lable }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const location = useLocation()
  const checkUserSelectedCompanyAccount =
    location.pathname.split('/')[2] === 'company'
  const companyId = location.pathname.split('/')[3]
  const investorUrl = `/dashboard/investor/fair-credit-portfolio`
  const companyUrl = `/dashboard/company/${companyId}/investor/fair-credit-portfolio`

  return (
    <DropdownItem onClick={() => history.push(url)}>
      <Link
        onClick={(e) => {
          e.preventDefault()
        }}
        to={url}
        className={
          checkUserSelectedCompanyAccount
            ? companyUrl.includes(url)
              ? 'dropdown-item-checked'
              : ''
            : investorUrl.includes(url)
            ? 'dropdown-item-checked'
            : ''
        }
      >
        {t(lable)}
      </Link>
    </DropdownItem>
  )
}

export default NavMenuItem
