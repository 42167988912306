import React from 'react'
import { Col, Row } from 'reactstrap'
import InvoiceTypeEnum from './InvoiceType.enum'
import { formatMoney } from '../../../../utils'

interface Props {
  title: string
  amount?: number
  invoiceStatus: InvoiceTypeEnum
}

const InstalmentMoreInfoRow = (props: Props) => {
  if (!props.amount) {
    return null
  }

  return (
    <Row
      className={`payment-plan__dropdown ${getClassName(props.invoiceStatus)}`}
    >
      <Col xs="8">{props.title}</Col>
      <Col xs="4" lg="4" className="payment-plan__dropdown-amount">
        {formatMoney(props.amount)}€
      </Col>
    </Row>
  )
}

function getClassName(invoiceStatus: InvoiceTypeEnum) {
  switch (invoiceStatus) {
    case InvoiceTypeEnum.READY_FOR_INVOICING:
      return 'up-coming'
    case InvoiceTypeEnum.PARTIALLY_PAID:
    case InvoiceTypeEnum.INVOICED:
      return 'current'
    default:
      return 'completed'
  }
}

export default InstalmentMoreInfoRow
