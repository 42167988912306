import 'bootstrap/dist/css/bootstrap.css'
import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Footer from '../components/Footer'
import Restricted from './Restricted'
import Dashboard from './Dashboard'
import Login from './Login'
import Register from './Register'
import RejectedOnFido from './RejectedOnFido'
import TermsAndConditions from '../components/Auth/TermsAndConditions/SignContractPage'
import InvestorOnboarding from './InvestorOnboarding'
import CompanyOnboarding from './CompanyOnboarding'
import SignicatCallBack from '../components/Auth/SignicatCallBack'
import LoginGuardRoute from './guards/LoginGuardRoute'
import AuthenticatedGuardRoute from './guards/AuthenticatedGuardRoute'
import RejectedOnFidoGuardRoute from './guards/RejectedOnFidoGuardRoute'
import TermsAndConditionsGuardRoute from './guards/TermsAndConditionsGuardRoute'
import LegacyGuardRoute from './guards/LegacyGuardRoute'
import NavBar from '../components/NavBar'
import Indicator from '../components/shared/Indicator'
import { compose } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { State } from '../store'
import { scroll, ScrollElement } from '../utils/scroll'
import { Redirect, useHistory } from 'react-router'
import Aisp from './Aisp'
import { countries } from '../constants'
import { ThunkDispatch } from 'redux-thunk'
import MappedDispatchModel from '../components/Footer/MappedDispatch.model'
import updateUserProfile from '../store/user/profile/actions/updateUserProfile'
import Marketing from '../components/Borrower/LoanApplication/Marketing'
import { removeRedirectPathAfterLogin } from '../utils/auth'
import ShouldAgreeWithTermAndConditionsGuardRoute from './guards/ShouldAgreeWithTermAndConditionsGuardRoute'
import AuthenticatedOrMoveToRegisterGuardRoute from './guards/AuthenticatedOrMoveToRegisterGuardRoute'
import TinkCallback from '../components/Borrower/AISP/TinkCallback'
import { virtualPageView } from '../utils/gtm/virtualPageView'
import OnFidoRedirect from '../components/Investor/OnFidoRedirect'

const scrollExceptionPathList = [
  '/restricted/investor/withdraw',
  '/restricted/investor',
  '/dashboard/investor/withdraw',
  '/dashboard/investor',
  '/dashboard/investor/fair-credit-portfolio',
  '/dashboard/investor/fixed-rate-portfolio',
  '/dashboard/company',
  '/dashboard/company/fixed-rate-portfolio',
]

const authTargetUrlExceptionPathList = [
  '/borrower/onboarding',
  '/onboarding/investor',
  '/login/sms/code',
  '/login',
  '/login/sms/verify',
  '/login/bank',
  '/login/bank/terms-and-conditions',
  '/login/bank/terms-and-conditions-and-price-list',
  '/register/verify-phone-number',
  '/register/identity-verification',
  '/register/identity-verification/terms-and-conditions',
  '/register/identity-verification/terms-and-conditions-and-price-list',
  '/register/provide-details',
  '/register/rejected',
  '/borrower/loan-offer/accept',
  '/register',
  '/auth/callback',
  '/onfido/redirect',
]

interface IProps
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const App = (props: IProps) => {
  const location = useLocation()
  const history = useHistory()
  const { changeUserLanguage } = props

  useEffect(
    () => {
      if (!scrollExceptionPathList.includes(location.pathname)) {
        scroll('top-of-the-page')
      }

      if (!authTargetUrlExceptionPathList.includes(location.pathname)) {
        removeRedirectPathAfterLogin()
      }

      // if path ends with /en, /fi, /se etc we are going to change language then remove path from url and redirect user
      const path = location.pathname.split('/')
      const lastPartOfPath = path.pop()
      if (countries.map((country) => country.locale).includes(lastPartOfPath)) {
        changeUserLanguage(lastPartOfPath)
        history.replace(path.join('/') + location.search)
      }
    },
    // eslint-disable-next-line
    [location]
  )

  useEffect(() => {
    virtualPageView(location.pathname)
  }, [location])

  return (
    <div id="main">
      <ScrollElement name="top-of-the-page" />
      <NavBar />
      {props.isFetchingUserProfile ? (
        <Indicator isVisible={true} />
      ) : (
        <Switch>
          <Route exact path="/">
            <Redirect to={{ pathname: '/dashboard' }} />
          </Route>
          {/* @ts-ignore */}
          <ShouldAgreeWithTermAndConditionsGuardRoute path="/dashboard">
            <Switch>
              {/* @ts-ignore */}
              <AuthenticatedGuardRoute path="/dashboard">
                <Dashboard />
              </AuthenticatedGuardRoute>
            </Switch>
          </ShouldAgreeWithTermAndConditionsGuardRoute>
          {/* @ts-ignore */}
          <LoginGuardRoute path="/login">
            <Login />
          </LoginGuardRoute>
          {/* @ts-ignore */}
          <LoginGuardRoute path="/register">
            <Register />
          </LoginGuardRoute>
          {/* @ts-ignore */}
          <TermsAndConditionsGuardRoute path="/terms-and-conditions">
            <TermsAndConditions />
          </TermsAndConditionsGuardRoute>
          {/* @ts-ignore */}
          <RejectedOnFidoGuardRoute path="/onfido/rejected">
            <RejectedOnFido />
          </RejectedOnFidoGuardRoute>
          {/* @ts-ignore */}
          <AuthenticatedOrMoveToRegisterGuardRoute path="/onboarding/investor">
            <InvestorOnboarding />
          </AuthenticatedOrMoveToRegisterGuardRoute>
          {/* @ts-ignore */}
          <AuthenticatedOrMoveToRegisterGuardRoute path="/onboarding/company">
            <CompanyOnboarding />
          </AuthenticatedOrMoveToRegisterGuardRoute>
          {/* @ts-ignore */}
          <LegacyGuardRoute path="/restricted">
            <Restricted />
          </LegacyGuardRoute>
          <Route path="/auth/callback">
            <SignicatCallBack />
          </Route>
          <Route path="/aisp">
            <Aisp />
          </Route>
          {/* @ts-ignore */}
          <AuthenticatedGuardRoute path="/tink-callback">
            <TinkCallback />
          </AuthenticatedGuardRoute>
          {/* @ts-ignore */}
          <AuthenticatedOrMoveToRegisterGuardRoute path="/borrower/onboarding">
            <Marketing />
          </AuthenticatedOrMoveToRegisterGuardRoute>
          {/* @ts-ignore */}
          <AuthenticatedGuardRoute path="/borrower/loan-offer/accept">
            <Redirect
              to={{ pathname: '/dashboard/borrower/loan-application' }}
            />
          </AuthenticatedGuardRoute>
          <Route path="/onfido/redirect">
            <OnFidoRedirect />
          </Route>
        </Switch>
      )}
      <Footer />
    </div>
  )
}

interface MappedStateModel {
  isFetchingUserProfile: boolean
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatchModel => ({
  changeUserLanguage: (language: string) =>
    dispatch(updateUserProfile({ language })),
})

const mapStateToProps = (state: State): MappedStateModel => ({
  isFetchingUserProfile: state.user.profile.is_fetching,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedStateModel, MappedDispatchModel, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(App)
