import { compose } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import LoanCalculationRequest from '../../../models/Borrower/LoanCalculationRequest'
import getCalculatedLoan from '../../../store/user/borrower/loan_calculator/actions/getCalculatedLoan'
import storeLoanApplication from '../../../store/user/borrower/loan_application/actions/storeLoanApplication'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { State } from '../../../store'
import StoreLoanApplicationRequest from '../../../models/Borrower/StoreLoanApplicationRequest'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Indicator from '../../shared/Indicator'
import LoanCalculatorFormElement from './LoanCalculatorFormElement'
import RangeInput from '../../shared/RangeInput'
import { productDetail } from '../../../utils/gtm/purchase'
import browserHistory from 'browserHistory'
import { FINNISH_NATIONALITY } from '../../../constants'

interface Props extends WithTranslation, MappedDispatch, MappedState {}

const LoanCalculator = (props: Props) => {
  const {
    t,
    getCalculatedLoan,
    monthlyLoanInstallment,
    isFetching,
    storeLoanApplication,
    disclaimers,
    isHidden,
    blaId,
    brokerRequestedAmount,
    brokerRequestedTerm,
    isOnFidoUser,
    nationality,
  } = props
  const [monthly, setMonthly] = useState(0)
  const [debounce, setDebounce] = useState(false)
  const [fields, setFields] = useState({ amount: 7500, term: 60 })

  const localHandleChange = (
    field: string,
    e: React.FormEvent<HTMLInputElement>
  ) => handleChange(field, fields, setFields, e)

  useEffect(() => {
    if (blaId && brokerRequestedAmount && brokerRequestedTerm) {
      setFields({
        amount: parseInt(brokerRequestedAmount),
        term: parseInt(brokerRequestedTerm),
      })
      getCalculatedLoan({
        amount: parseInt(brokerRequestedAmount),
        term: parseInt(brokerRequestedTerm),
      })
      setMonthly(monthlyLoanInstallment)
    }
  }, [
    blaId,
    brokerRequestedAmount,
    brokerRequestedTerm,
    getCalculatedLoan,
    setMonthly,
    monthlyLoanInstallment,
  ])

  useEffect(
    () => {
      getCalculatedLoan({ amount: fields['amount'], term: fields['term'] })
      setMonthly(monthlyLoanInstallment)
    },
    // eslint-disable-next-line
    [monthlyLoanInstallment]
  )

  function handleChange(
    field,
    fields,
    setFields,
    e: React.FormEvent<HTMLInputElement>
  ) {
    if (!debounce) {
      fields[field] = Number(e.currentTarget.value)
      const deepCopy = { ...fields }
      setFields(deepCopy)
      getCalculatedLoan({ amount: fields['amount'], term: fields['term'] })
      setDebounce(true)
      const debounceTimer = setTimeout(() => {
        setDebounce(false)
        clearTimeout(debounceTimer)
      }, 500)
    }
  }

  function onClickTakeLoan() {
    if (nationality === FINNISH_NATIONALITY && !isOnFidoUser) {
      storeLoanApplication({ amount: fields['amount'], terms: fields['term'] })
      productDetail(fields.amount, fields.term)
    } else {
      browserHistory.push('/dashboard/investor')
    }
  }

  if (isHidden) {
    return null
  }

  return (
    <>
      <div>
        <Indicator isVisible={isFetching} />
        <section className="loan-calculator__wrapper">
          <div className="container">
            <h2>{t('GET_YOUR_LOAN_IN_MINUTES')}</h2>
            <p className="font-13">{t('CHOOSE_AMOUNT_AND_LOAN_TIME')}</p>
            <Row className="justify-content-between align-items-center mt-5">
              <LoanCalculatorFormElement
                value={fields['amount']}
                symbol="€"
                title={t('AMOUNT')}
              >
                <RangeInput
                  value={fields['amount']}
                  handleChange={(e) => localHandleChange('amount', e)}
                  id="amount__range"
                  step={500}
                  min={1000}
                  max={20000}
                />
              </LoanCalculatorFormElement>

              <LoanCalculatorFormElement
                value={fields['term']}
                symbol="kk"
                title={t('LOAN_TIME')}
              >
                <RangeInput
                  value={fields['term']}
                  handleChange={(e) => localHandleChange('term', e)}
                  id="term__range"
                  step={12}
                  min={24}
                  max={120}
                />
              </LoanCalculatorFormElement>

              <LoanCalculatorFormElement
                value={monthly}
                title={t('MONTHLY_PAY')}
                symbol="€"
              >
                <span className="monthly-pay__dotted-line" />
              </LoanCalculatorFormElement>
            </Row>

            <Row className="d-flex justify-content-between align-items-center mt-5">
              <Col lg="9" className="mb-3">
                {disclaimers}
              </Col>
              <Col lg="3" className="text-center take-loan__btn">
                <Button onClick={onClickTakeLoan}>{t('TAKE_LOAN')}</Button>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  )
}

export interface MappedState {
  disclaimers: string
  monthlyLoanInstallment: number
  isFetching: boolean
  isHidden: boolean
  brokerRequestedAmount?: string
  brokerRequestedTerm?: string
  blaId?: string
  isOnFidoUser: number
  nationality?: string
}

export interface MappedDispatch {
  getCalculatedLoan: (data: LoanCalculationRequest) => void
  storeLoanApplication: (data: StoreLoanApplicationRequest) => void
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getCalculatedLoan: (data: LoanCalculationRequest) =>
    dispatch(getCalculatedLoan(data)),
  storeLoanApplication: (data: StoreLoanApplicationRequest) =>
    dispatch(storeLoanApplication(data)),
})

const mapStateToProps = (state: State): MappedState => {
  let language = state.user.profile.data.language
  if (!language) {
    language = 'en'
  }
  if (language === 'se') {
    language = 'sv'
  }
  return {
    disclaimers: state.user.borrower.loan_calculator.data.disclaimers[language],
    monthlyLoanInstallment:
      state.user.borrower.loan_calculator.data['monthly-loan-instalment'],
    isFetching:
      state.user.borrower.loan.is_fetching ||
      state.user.borrower.loan_application.is_fetching,
    isHidden: state.user.borrower.loan_application.data.id !== 0,
    blaId: state.auth.register.sms.blaId
      ? state.auth.register.sms.blaId
      : state.auth.register.signicat.callback_response.blaId ||
        state.auth.login.sms.blaId,
    brokerRequestedAmount: state.broker.loanApplicationRequest.requested_amount,
    brokerRequestedTerm: state.broker.loanApplicationRequest.requested_term,
    isOnFidoUser: state.user.profile.data.is_foreigner,
    nationality: state.user.profile.data.nationality,
  }
}

const enhance = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhance(LoanCalculator)
