import { Col } from 'reactstrap'
import React from 'react'

interface Props {
  value: string | number
  title: string
  symbol: string
}

const LoanCalculationFormElement = (props: React.PropsWithChildren<Props>) => {
  const { value, children, title, symbol } = props

  return (
    <Col
      lg="3"
      className="d-flex justify-content-center align-items-center flex-column"
    >
      <h2>
        {value} {symbol}
      </h2>
      <span className="font-13 mb-4">{title}</span>
      {children}
    </Col>
  )
}

export default LoanCalculationFormElement
