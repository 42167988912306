import React from 'react'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import browserHistory from '../../../browserHistory'
import { Row, Col } from 'reactstrap'

interface Props extends WithTranslation {}

const LoanRejection = (props: Props) => {
  const { t } = props
  const url = `https://rahoitu.fi/application?pubid=702204`

  function redirectToBroker() {
    window.open(url, '_blank')
  }

  return (
    <div className="loan-rejection__wrapper">
      <aside className="loan-reject-redirect-message__container">
        <h1 className="loan__main-title pb-15">
          {t('LOAN_REJECTION_REDIRECT_TITLE')}
        </h1>
        <p>{t('LOAN_REJECTION_REDIRECT_DETAIL')}</p>
        <p>{t('LOAN_REJECTION_REDIRECT_DETAIL_SECOND_LINE')}</p>
        <Button
          className="redirect-broker-btn"
          color="secondary"
          onClick={redirectToBroker}
        >
          {t('LOAN_REJECTION_REDIRECT_BUTTON')}
        </Button>
        <Row className="redirect-btn">
          <Col>
            <a href={`https://fixura.com/`}>
              {t('LOAN_REJECTION_REDIRECT_MARKETING_SITE')}
            </a>
          </Col>
        </Row>
      </aside>
      {/* Hidden previous loan rejection page */}
      <aside className="loan-reject-message__container hide">
        <h1 className="loan__main-title">{t('LOAN_REJECTION_TITLE')}</h1>
        <p className="loan-rejection__description">
          {t('LOAN_REJECTION_DETAIL')}
        </p>
        <Button
          className="hero light loan-back-to-home__btn"
          color="secondary"
          onClick={() => browserHistory.push('/dashboard')}
        >
          {t('BACK_TO_HOME')}
        </Button>
      </aside>
    </div>
  )
}

const enhance = compose(withTranslation())

export default enhance(LoanRejection)
