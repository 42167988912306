import {
  STORE_LOAN_FAILURE,
  STORE_LOAN_REQUEST,
  STORE_LOAN_SUCCESS,
} from './actions/types'

export default (state = initialState, action) => {
  switch (action.data) {
    case STORE_LOAN_REQUEST:
      return { ...initialState, is_fetching: true }
    case STORE_LOAN_SUCCESS:
      return initialState
    case STORE_LOAN_FAILURE:
      return { ...initialState, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  error: null,
}

export interface State {
  is_fetching: boolean
  error: any
}
