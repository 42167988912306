import React from 'react'
import { useLocation } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import LoginLink from './LoginLink'
import LoginType from './LoginType'

interface Props extends WithTranslation {}

const Login = (props: React.PropsWithChildren<Props>) => {
  const location = useLocation()
  const { t } = props

  return (
    <div className="login container login-p-5" id="container">
      {!location.pathname.includes('verify') && (
        <h1 className="font-50 text-center d-block pb-10">{t('LOGIN')}</h1>
      )}
      <div className="text-center">
        {props.children}
        {!location.pathname.includes('sms') && (
          <LoginLink type={LoginType.LOGIN_PHONE_NUMBER} />
        )}
        {!location.pathname.includes('bank') && (
          <LoginLink type={LoginType.LOGIN_SCA} />
        )}
        {!location.pathname.includes('email') && (
          <LoginLink type={LoginType.LOGIN_EMAIL} />
        )}
      </div>
    </div>
  )
}

const enhancer = compose(withTranslation())

export default enhancer(Login)
