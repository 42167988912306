enum InvoiceTypeEnum {
  READY_FOR_INVOICING = 'READY_FOR_INVOICING',
  INVOICED = 'INVOICED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  OVERPAID = 'OVERPAID',
  CANCELED = 'CANCELED',
  PAID_LATE = 'PAID_LATE',
}

export default InvoiceTypeEnum
