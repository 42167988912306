import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import {
  createStoreLoanApplicationFailureAction,
  createStoreLoanApplicationRequestAction,
  createStoreLoanApplicationSuccessAction,
} from './creators'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import browserHistory from '../../../../../browserHistory'
import { AxiosResponse } from 'axios'
import StoreLoanApplicationRequest from '../../../../../models/Borrower/StoreLoanApplicationRequest'

const storeLoanApplication = (
  data: StoreLoanApplicationRequest
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createStoreLoanApplicationRequestAction())

  try {
    const borrowerAPI = new BorrowerAPI()
    const response: AxiosResponse = await borrowerAPI.storeLoanApplication(data)
    /* TODO checkme for test */
    dispatch(
      createStoreLoanApplicationSuccessAction(
        response && response.data && response.data.data.loan_application
      )
    )
    browserHistory.push('/dashboard/borrower/ccis')
  } catch (error) {
    dispatch(createStoreLoanApplicationFailureAction(error))
    /* TODO checkme for test */

    if (
      (error.response && error.response.status === 400) ||
      (error.response && error.response.status === 403)
    ) {
      browserHistory.push('/dashboard/borrower/loan-rejected')
    }
  }
}

export default storeLoanApplication
