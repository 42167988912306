import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'
import postStoreLoan from '../../../store/user/borrower/store_loan/actions/postStoreLoan'
import { connect } from 'react-redux'
import { State } from '../../../store'
import React, { useEffect } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Button, Container } from 'reactstrap'
import Indicator from '../../shared/Indicator'
import ProfileData from '../../../models/Auth/ProfileData'
import Contract from '../../shared/Contract'
import { getContract } from '../../../store/user/borrower/loan_contract/actions/getContract'
import { checkoutStep4 } from '../../../utils/gtm/checkout'

interface Props extends WithTranslation, MappedState, MappedDispatch {}

const SignContract = (props: Props) => {
  const {
    t,
    signLoanContract,
    loanApplicationId,
    isFetching,
    data,
    getContract,
    contractHtml,
  } = props

  // google tag
  useEffect(() => {
    checkoutStep4()
  }, [])

  useEffect(() => {
    if (data.id) getContract()
  }, [data, getContract])

  function signingContract() {
    signLoanContract(loanApplicationId)
  }

  return (
    <Container className="contract__wrapper">
      <div className="text-center pt-5">
        <h1 className="font-50 step-big-title">{t('SIGN_CONTRACT')}</h1>
        <Indicator isVisible={isFetching} />
        <Contract html={contractHtml} />
        <Button className="my-5" onClick={signingContract}>
          {t('BORROWER_SIGN_CONTRACT_BUTTON')}
        </Button>
      </div>
    </Container>
  )
}

interface MappedState {
  loanApplicationId: number
  isFetching: boolean
  data: ProfileData
  contractHtml: string
  error: any
}

interface MappedDispatch {
  signLoanContract: (loanApplicationId: number) => void
  getContract: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  loanApplicationId: state.user.borrower.loan_application.data.id,
  isFetching:
    state.user.borrower.loan_contract.is_fetching ||
    state.user.borrower.store_loan.is_fetching ||
    state.user.profile.is_fetching,
  data: state.user.profile.data,
  contractHtml: state.user.borrower.loan_contract.html,
  error: state.user.borrower.loan_contract.error,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  signLoanContract: (loanApplicationId: number) =>
    dispatch(postStoreLoan(loanApplicationId)),
  getContract: () => dispatch(getContract()),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, MappedDispatch, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(SignContract)
