import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import browserHistory from '../../browserHistory'
import { withTranslation, WithTranslation } from 'react-i18next'
import { isLegacy } from '../../utils/auth'
import { State } from '../../store'
import { compose } from 'redux'
import { connect } from 'react-redux'
import LegacyGreet from '../Borrower/Dashboard/LegacyGreet'
import start_investing_en from 'assets/images/start_investing_en.jpg'
import start_investing_fi from 'assets/images/start_investing_fi.jpg'
import start_investing_se from 'assets/images/start_investing_se.jpg'
import { ScrollElement } from 'utils/scroll'

interface Props extends WithTranslation, MappedState {}

const StartInvesting = (props: Props) => {
  const { t, language, isVisible } = props
  const is_legacy = isLegacy()

  if (!isVisible && is_legacy) {
    return <LegacyGreet />
  }

  return (
    <Container className="greet__wrapper">
      <ScrollElement name="start" />
      <Row>
        <Col md={12}>
          <h1 className="greet">
            {is_legacy ? t('CONTINUE_INVESTING') : t('START_INVESTING_P2P')}
          </h1>
        </Col>
        <Col xl={6} lg={12}>
          <Row>
            <Col md={12}>
              {is_legacy && <p>{t('START_INVESTING_INFO')}</p>}
              <p>{t('CORONA_VIRUS_SITUATION')}</p>
            </Col>
          </Row>
          <Row className="content">
            <Col md={12}>
              {is_legacy && (
                <h2 className="intro">{t('START_INVESTING_INTRO')}</h2>
              )}
            </Col>
            <Col md={6}>
              <h3>{t('START_INVESTING_FIRST_TITLE')}</h3>
              <p>{t('START_INVESTING_FIRST_CONTENT')}</p>
            </Col>
            <Col md={6}>
              <h3>{t('START_INVESTING_SECOND_TITLE')}</h3>
              <p>{t('START_INVESTING_SECOND_CONTENT')}</p>
            </Col>
            <Col md={6}>
              <h3>{t('START_INVESTING_THIRD_TITLE')}</h3>
              <p>{t('START_INVESTING_THIRD_CONTENT')}</p>
            </Col>
            <Col md={6}>
              <h3>{t('START_INVESTING_FOURTH_TITLE')}</h3>
              <p>{t('START_INVESTING_FOURTH_CONTENT')}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                {is_legacy
                  ? t('CONTINUE_INVESTING_OUTRO')
                  : t('START_INVESTING_OUTRO')}
              </p>
              <Button
                onClick={() =>
                  browserHistory.push(
                    `/onboarding/investor${is_legacy ? '' : '/aml'}`
                  )
                }
                className="mt-5"
                color="secondary"
              >
                {is_legacy
                  ? t('CONTINUE_INVESTING_BUTTON')
                  : t('START_INVESTING')}
              </Button>
              <p>{t('START_INVESTING_ESTIMATED')}</p>
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={12} className="image_area">
          <img src={getImageByName(language)} alt="start investing" />
        </Col>
      </Row>
    </Container>
  )
}

const getImageByName = (name: string) => {
  switch (name) {
    case 'fi':
      return start_investing_fi
    case 'se':
      return start_investing_se
    default:
      return start_investing_en
  }
}

interface MappedState {
  language: string
  isVisible: boolean
}

const mapStateToProps = (state: State): MappedState => ({
  language: state.user.profile.data.language,
  isVisible: state.legacy.auth.data.can_be_onboarded,
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MappedState, any, any, any>(mapStateToProps)
)

export default enhancer(StartInvesting)
