import React from 'react'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import aispRetry from '../../../store/user/borrower/loan_application/actions/aispRetry'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Col, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { TINK_LINK_URL } from '../../../constants'
import Indicator from '../../shared/Indicator'
import moment from 'moment'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/de'

interface Props extends MappedState, MappedDispatch {}

const ActiveLoanAction = (props: Props) => {
  const {
    isHidden,
    isRejected,
    isAispRetryAvailable,
    shouldCheckStatus,
    id,
    aispRetry,
    shouldGoToChooseBankPage,
    shouldRedirectToTinkLink,
    shouldRedirectToCCIS,
    validUntilDate,
    tryAfterThisDate,
    isFetching,
  } = props

  const { t } = useTranslation()
  const browserHistory = useHistory()

  if (isHidden) {
    return null
  }

  return (
    <>
      <Indicator isVisible={isFetching} />
      <Row className="text-center pt-1 pb-5 offer-information">
        <Col>
          {shouldRedirectToCCIS ? (
            <Button
              className="hero"
              onClick={() => browserHistory.push('/dashboard/borrower/ccis')}
            >
              {t('CONTINUE_APPLICATION')}
            </Button>
          ) : (
            <>
              {shouldGoToChooseBankPage && (
                <Button
                  className="hero"
                  onClick={() =>
                    browserHistory.push(
                      '/dashboard/borrower/loan-application/choose-bank'
                    )
                  }
                >
                  {t('CONTINUE_APPLICATION')}
                </Button>
              )}
              {shouldRedirectToTinkLink && (
                <>
                  <Button className="hero" href={TINK_LINK_URL}>
                    {t('CONTINUE_APPLICATION')}
                  </Button>
                  <div className="offer-information__value my-4">
                    {`${t('VALID_APPLICATION')} ${validUntilDate}`}
                  </div>
                </>
              )}
              {isAispRetryAvailable && (
                <>
                  <Button className="hero" onClick={aispRetry}>
                    {t('CONTINUE_APPLICATION')}
                  </Button>
                  <div className="offer-information__value my-4">
                    {`${t('VALID_APPLICATION')} ${validUntilDate}`}
                  </div>
                </>
              )}
              {shouldCheckStatus && (
                <Button
                  className="hero"
                  onClick={browserHistory.push(`/aisp/success?id=${id}`)}
                >
                  {t('CONTINUE_APPLICATION')}
                </Button>
              )}
              {isRejected && (
                <span>
                  {`${t(
                    'YOUR_LOAN_HAS_BEEN_REJECTED_PLEASE_TRY'
                  )} ${tryAfterThisDate} ${t('LATER')}`}
                </span>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

interface MappedState {
  isHidden: boolean
  isAispRetryAvailable: boolean
  shouldCheckStatus: boolean
  isRejected: boolean
  isFetching: boolean
  shouldGoToChooseBankPage: boolean
  shouldRedirectToTinkLink: boolean
  shouldRedirectToCCIS: boolean
  id: number
  validUntilDate: string
  tryAfterThisDate: string
}

interface MappedDispatch {
  aispRetry: () => void
}

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.borrower.loan_application
  const date = state.user.borrower.loan_application.data.created_at
  const isFetching = state.user.borrower.loan_application.is_fetching

  const language =
    state.user.profile.data.language === 'se'
      ? 'sv'
      : state.user.profile.data.language
  const validUntilDate = moment(date)
    .add(3, 'days')
    .locale(language)
    .format('lll')

  const tryAfterThisDate = moment(date)
    .add(91, 'days')
    .locale(language)
    .format('ll')

  return {
    isHidden: data.id === 0,
    isAispRetryAvailable:
      data.service === 'asiakastieto' && !!data.retry && !!data.link,
    shouldCheckStatus: !!data.retry && !data.link,
    isRejected: !data.retry,
    shouldGoToChooseBankPage:
      data.service === 'neonomics' && !!data.retry && !!data.link,
    shouldRedirectToTinkLink:
      data.service === 'nordigen-tink' && !!data.retry && !!data.link,
    shouldRedirectToCCIS: data.state === 'bs4',
    id: data.id,
    validUntilDate: validUntilDate,
    tryAfterThisDate: tryAfterThisDate,
    isFetching,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  aispRetry: () => dispatch(aispRetry()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ActiveLoanAction)
