import BackButton from '../../shared/BackButton'
import MarketingSection from '../LoanCalculator/MarketingSection'
import LoanCalculator from '../LoanCalculator'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import getActiveLoanApplication from '../../../store/user/borrower/loan_application/actions/getActiveLoanApplication'
import getBrokerLoanApplicationRequest from '.../../store/broker/loanApplicationRequest/actions/getBrokerLoanApplicationRequest'
import ActiveLoan from './ActiveLoan'
import CustomerService from '../../shared/CustomerService'
import { State } from '../../../store'
import checkLoanStatus from '../../../store/user/borrower/loan_application/actions/checkLoanStatus'

interface Props extends MappedDispatch, MappedState {}

const LoanApplication = (props: Props) => {
  const {
    getActiveLoans,
    getBrokerLoanApplicationRequest,
    blaId,
    checkLoanStatus,
    loanApplicationId,
    retry,
  } = props

  useEffect(() => {
    getActiveLoans()
  }, [getActiveLoans])

  //TODO check me logic
  useEffect(() => {
    if (loanApplicationId && retry) checkLoanStatus(loanApplicationId)
  }, [checkLoanStatus, loanApplicationId, retry])

  useEffect(() => {
    if (blaId) {
      getBrokerLoanApplicationRequest()
    }
  }, [blaId, getBrokerLoanApplicationRequest])

  return (
    <>
      <BackButton />
      <MarketingSection />
      <LoanCalculator />
      <ActiveLoan />
      <CustomerService />
    </>
  )
}

export interface MappedState {
  blaId: string | undefined
  loanApplicationId: number
  retry: boolean
}

const mapStateToProps = (state: State): MappedState => ({
  loanApplicationId: state.user.borrower.loan_application.data.id,
  blaId: state.auth.register.sms.blaId
    ? state.auth.register.sms.blaId
    : state.auth.register.signicat.callback_response.blaId ||
      state.auth.login.sms.blaId,
  retry: !!state.user.borrower.loan_application.data.retry,
})
interface MappedDispatch {
  getActiveLoans: () => void
  getBrokerLoanApplicationRequest: () => void
  checkLoanStatus: (loanApplicationId: number) => void
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getActiveLoans: () => dispatch(getActiveLoanApplication()),
  getBrokerLoanApplicationRequest: () =>
    dispatch(getBrokerLoanApplicationRequest()),
  checkLoanStatus: (loanApplicationId: number) =>
    dispatch(checkLoanStatus(loanApplicationId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(LoanApplication)
