import React from 'react'
import { useTranslation } from 'react-i18next'

const MarketingSection = () => {
  const { t } = useTranslation()
  return (
    <section className="p-3 text-center">
      <h1>{t('APPLY_FOR_A_NEW_LOAN')}</h1>
      <div>
        <p className="my-2 font-weight-bolder">{t('APPLY_NOW')}</p>
        <p className="mb-1">{t('MARKETING_BOTTOM_DESCRIPTION_FIRST_LINE')}</p>
        <p>{t('MARKETING_BOTTOM_DESCRIPTION_SECOND_LINE')}</p>
      </div>
    </section>
  )
}

export default MarketingSection
