import {
  AISP_RETRY_FAILURE,
  AISP_RETRY_REQUEST,
  AISP_RETRY_SUCCESS,
  GET_ACTIVE_LOANS_FAILURE,
  GET_ACTIVE_LOANS_REQUEST,
  GET_ACTIVE_LOANS_SUCCESS,
  GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE,
  GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST,
  GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS,
  STORE_LOAN_APPLICATION_FAILURE,
  STORE_LOAN_APPLICATION_REQUEST,
  STORE_LOAN_APPLICATION_SUCCESS,
  UPDATE_LOAN_APPLICATION_FAILURE,
  UPDATE_LOAN_APPLICATION_REQUEST,
  UPDATE_LOAN_APPLICATION_SUCCESS,
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  CREATE_BANK_SESSION_REQUEST,
  CREATE_BANK_SESSION_SUCCESS,
  CREATE_BANK_SESSION_FAILURE,
  POST_TINK_CODE_REQUEST,
  POST_TINK_CODE_SUCCESS,
  POST_TINK_CODE_FAILURE,
  UPDATE_IS_CHECKED_CCIS_SUCCESS,
  UPDATE_IS_CHECKED_CCIS_REQUEST,
  UPDATE_IS_CHECKED_CCIS_FAILURE,
  TOGGLE_IS_CHECKED_CCIS,
} from './types'
import LoanApplication from '../../../../../models/Borrower/LoanApplication'
import Bank from '../../../../../models/Borrower/Bank'
import { Action, AnyAction } from 'redux'

export const createStoreLoanApplicationRequestAction = () => ({
  type: STORE_LOAN_APPLICATION_REQUEST,
})
export const createStoreLoanApplicationSuccessAction = (
  data: LoanApplication
) => ({
  type: STORE_LOAN_APPLICATION_SUCCESS,
  data,
})
export const createStoreLoanApplicationFailureAction = (error: any) => ({
  error,
  type: STORE_LOAN_APPLICATION_FAILURE,
})

export const createAispRetryRequestAction = () => ({ type: AISP_RETRY_REQUEST })
export const createAispRetrySuccessAction = () => ({ type: AISP_RETRY_SUCCESS })
export const createAispRetryFailureAction = (error: any) => ({
  error,
  type: AISP_RETRY_FAILURE,
})

export const createGetCheckLoanApplicationStatusRequestAction = () => ({
  type: GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST,
})
export const createGetCheckLoanApplicationStatusSuccessAction = (
  data: LoanApplication
) => ({
  data,
  type: GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS,
})
export const createGetCheckLoanApplicationStatusFailureAction = (
  error: any
) => ({
  error,
  type: GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE,
})
export const createGetActiveLoanApplicationRequestAction = () => ({
  type: GET_ACTIVE_LOANS_REQUEST,
})
export const createGetActiveLoanApplicationSuccessAction = (
  data: LoanApplication
) => ({
  data,
  type: GET_ACTIVE_LOANS_SUCCESS,
})
export const createGetActiveLoanApplicationFailureAction = (error: any) => ({
  error,
  type: GET_ACTIVE_LOANS_FAILURE,
})

export const createUpdateLoanApplicationRequestAction = () => ({
  type: UPDATE_LOAN_APPLICATION_REQUEST,
})
export const createUpdateLoanApplicationSuccessAction = (
  data: LoanApplication
) => ({ data, type: UPDATE_LOAN_APPLICATION_SUCCESS })
export const createUpdateLoanApplicationFailureAction = (error: any) => ({
  error,
  type: UPDATE_LOAN_APPLICATION_FAILURE,
})

export const createFetchBanksRequestAction = () => ({
  type: FETCH_BANKS_REQUEST,
})
export const createFetchBanksSuccessAction = (data: Bank[]) => ({
  data,
  type: FETCH_BANKS_SUCCESS,
})
export const createFetchBanksFailureAction = (error) => ({
  error,
  type: FETCH_BANKS_FAILURE,
})

export const createCreateBankSessionRequestAction = () => ({
  type: CREATE_BANK_SESSION_REQUEST,
})
export const createCreateBankSessionSuccessAction = () => ({
  type: CREATE_BANK_SESSION_SUCCESS,
})
export const createCreateBankSessionFailureAction = (error) => ({
  error,
  type: CREATE_BANK_SESSION_FAILURE,
})

export const createPostTinkCodeRequestAction = (): Action => ({
  type: POST_TINK_CODE_REQUEST,
})
export const createPostTinkCodeSuccessAction = (): Action => ({
  type: POST_TINK_CODE_SUCCESS,
})
export const createPostTinkCodeFailureAction = (error): AnyAction => ({
  error,
  type: POST_TINK_CODE_FAILURE,
})

export const createUpdateCCISRequestAction = (): Action => ({
  type: UPDATE_IS_CHECKED_CCIS_REQUEST,
})
export const createUpdateCCISSuccessAction = (data): AnyAction => ({
  type: UPDATE_IS_CHECKED_CCIS_SUCCESS,
  data,
})
export const createUpdateCCISFailureAction = (error): AnyAction => ({
  type: UPDATE_IS_CHECKED_CCIS_FAILURE,
  error,
})

export const createToggleIsCheckedCCISAction = (): Action => ({
  type: TOGGLE_IS_CHECKED_CCIS,
})
