import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from '../../shared/Spinner'
import { ThunkDispatch } from 'redux-thunk'
import checkLoanStatus from '../../../store/user/borrower/loan_application/actions/checkLoanStatus'
import { connect } from 'react-redux'
import { State } from '../../../store'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

interface Props extends MappedDispatch, MappedState {}

const GatherInformation = (props: Props) => {
  const { checkLoanStatus } = props
  const { t } = useTranslation()
  const location = useLocation()
  const qs = queryString.parse(location.search)

  useEffect(
    () => {
      let interval
      checkLoanStatus(Number(qs.id))
      interval = setInterval(() => {
        checkLoanStatus(Number(qs.id))
      }, 10000)

      // component will unmount
      return () => {
        if (interval) {
          clearInterval(interval)
        }
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <div className="m-5 p-5">
      <Spinner spinnerHeight={150} spinnerWidth={150} />
      <h1 className="text-center mt-5">{t('PROCESSING_THE_APPLICATION')}</h1>
      <h6 className="text-center pb-5">{t('DO_NOT_REFRESH')}.</h6>
    </div>
  )
}

interface MappedState {
  retry: boolean
}

interface MappedDispatch {
  checkLoanStatus: (loanApplicationId: number) => void
}

const mapStateToProps = (state: State): MappedState => ({
  retry: !!state.user.borrower.loan_application.data.retry,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  checkLoanStatus: (loanApplicationId: number) =>
    dispatch(checkLoanStatus(loanApplicationId)),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(GatherInformation)
