import React from 'react'

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>Fixuras dataskyddsbeskrivning</h3>
      <p>
        I denna dataskyddsbeskrivning kan du läsa om hur vi samlar in och
        använder dina personuppgifter. Du kan även se vilka dina rättigheter är
        och hur du kan använda dem. I enlighet med dataskyddslagen och annan
        lagstiftning i Finland har vi förbundit oss att skydda våra användare.
        Det är viktigt att du läser igenom denna beskrivning och att du är
        införstådd med vårt dataskydd innan du använder dig av våra tjänster.
      </p>

      <h3>Centrala begrepp</h3>
      <p>
        Med personuppgifter avser vi all information om en levande person, dessa
        uppgifter är sådana att man kan känna igen en person eller bli igenkänd
        av. Personuppgifter kan vara namn, adress och telefonnummer. Även den
        IP-adress som syns då du besökt våra hemsidor kan vara personuppgifter.
      </p>
      <p>
        Med hantering av personuppgifter avser vi all hantering av
        personuppgifter som till exempel insamling av personuppgifter,
        analysering, ändring och förvaring.
      </p>
      <p>
        Registeransvarig är den part som definierar användningen av
        personuppgifter och sätt. Registeransvariga svarar även för hur
        uppgifterna hanteras i enlighet med gällande lagstiftning.
      </p>
      <p>
        Med den registrerade avser vi användare som har registrerat sig för att
        använda Fixuras tjänster.
      </p>

      <h3>1. Registeransvarig</h3>
      <p>
        Fixura Ab Oy
        <br />
        FO-nummer 2246639-7
        <br />
        Hovrättsesplanaden 15 C 29, 65100 Vasa
        <br />
        +358-(0)20 7344 530
        <br />
        E-post: <a href="mailto:info@fixura.com">info@fixura.com</a>
      </p>

      <h3>2. Kontakt i registerärenden</h3>
      <p>
        Förfrågningar och begäran om kontroll av egna uppgifter sänds till
        adressen <a href="mailto:info@fixura.com">info@fixura.com</a>
      </p>

      <h3>3. Registrets namn</h3>
      <p>Fixura Ab Oy:s kundregister</p>

      <h3>4. Syfte med behandling av personuppgifter</h3>
      <p>
        Syftet med behandling av personuppgifter är att ta hand om och utveckla
        uppdrag och tjänster i anslutning till Fixura Ab Oy:s kundregister.
        Personuppgifterna behövs för att fullfölja låne- och placeringsavtal
        samt upprätthålla kundförhållanden. Uppgifterna behövs bland annat även
        för låneklassificeringar, administration av kontaktuppgifter,
        direktmarknadsföring noggrannare riktande av tjänster med personifierat
        innehåll, riskbedömning som omfattar bl.a. verksamhet som strävar till
        att motverka penningtvätt, skötsel och organisering för verksamheten
        centrala skyldigheter som baserar sig på lagstiftning för att förvara,
        rapportera och sköta förfrågningar i enlighet med myndigheternas
        direktiv och anvisningar. Personuppgifterna behandlas inom de ramar som
        lagen tillåter och förpliktar till för att sköta kundkontakter,
        administration och utveckling, för analys, statistik samt för att
        producera och erbjuda informationstjänster.
      </p>
      <p>
        Fixura Ab Oy och de bolag som ingår i samma koncern har rätt även att
        använda och överlåta uppgifter som ingår i registret för vissa ändamål
        såsom i marknadsföringssyfte i enlighet med lagen om personuppgifter. I
        samband med registreringen kan personen själv godkänna användningen av
        sina personliga uppgifter i marknadsföringssyfte.
      </p>

      <h3>5. Registrets datainnehåll</h3>
      <p>I registret kan förvaras information enligt nedanstående:</p>
      <ul>
        <li>
          Den registrerade har eller har haft en kundrelation, gjort en ansökan
          för att upprätta en kundrelation, registrerat en kundrelation eller
          någon annan personlig kontakt med den registeransvariga.
        </li>
        <li>
          Den registrerade har eller har haft delaktighet i en kundrelation,
          skyldighet eller någon annan rätt till avtal med eller service av den
          registeransvariga eller rätt att ge uppdrag till den
          registeransvariga.
        </li>
        <li>
          Registrering av uppgifter baserar sig på en skyldighet enligt lag
        </li>
      </ul>

      <h3>Grundläggande personuppgifter såsom:</h3>
      <ul>
        <li>
          Namn, adress, postnummer, postadress, hemort, telefonnummer,
          bankuppgifter och e-postadress
        </li>
        <li>Datum när kundrelationen inletts och avslutats</li>
        <li>Födelsedatum och personnummer/FO-nummer</li>
        <li>Språk, nationalitet</li>
        <li>Beskattningsland</li>
        <li>
          Uppgifter om kundens ekonomiska situation (t.ex. utbildning, yrke,
          inkomstuppgifter)
        </li>
        <li>
          Direktmarknadsföringsförbud och samtycke till direktmarknadsföring
        </li>
        <li>IP-adresser</li>
        <li>
          Data om transaktioner, låntagare (användarnamn, ansökningar, avtal,
          fakturering och indrivning, betalningsanmärkningar)
        </li>
        <li>
          Data om transaktioner, investerare (användarnamn, avtal, uppgifter om
          investerare t.ex. erfarenhet av investering, grunduppgifter om
          företagaranvändare såsom företagets namn, FO-nummer, kontaktperson)
        </li>
        <li>
          Lagenliga förpliktelser och engagemang (t.ex. politiskt inflytelserik
          person)
        </li>
      </ul>

      <p>Uppgifter som kunden givit med ett specifikt godkännande:</p>
      <ul>
        <li>Uppgifter om bankkontotransaktioner</li>
        <li>
          De uppgifter som finns i Suomen Asiakastieto Oy:s förfrågningssystemet
          för konsumentkrediter
        </li>
      </ul>
      <p>
        I kundregistret sparas med kundens uttryckliga medgivande,
        banktransaktionerna på kundens konto från de senaste 12 månaderna, som
        använts vid behandling av en kreditansökan och vid den analys som gjorts
        för att bevilja krediten.
      </p>
      <p>
        Med kundens samtycke registreras i kundregistret vid en kreditansökan de
        uppgifter om tidigare krediter som erhållits från andra kreditgivare.
        Uppgifterna samlas in genom det förfrågningssystem som upprätthålls av
        Suomen Asiakastieto Oy.
      </p>

      <h3>6. Förvaring av personuppgifter</h3>
      <p>
        Vi förvarar de insamlade uppgifterna om den registrerade i vårt
        kundregister i fem år efter att den registrerades lån eller
        investeringar är återbetalade i sin helhet. Användarkontot kan på
        begäran inaktiveras under förvaringstiden och då kan kontot inte längre
        användas. Detta oaktat så sparas kontotransaktionerna enligt
        bokföringsföreskrifterna i minst tio år.
      </p>
      <p>
        Om den registrerade inte har något aktivt lån eller aktiva investeringar
        förvaras uppgifterna i två år efter den senaste registreringen,
        inloggningen, ansökan eller annan aktivitet.
      </p>

      <h3>7. Normala informationskällor</h3>
      <p>
        I registret samlas uppgifter om användaren i samband med registreringen
        och under kundförhållandet, till exempel när personen registrerar sig i
        portalen eller uppdaterar sina uppgifter under kundförhållandet.
        Personuppgifter kan samlas in och/eller verifieras från följande källor:
      </p>
      <ul>
        <li>Av kunden själv i samband med registreringen eller senare</li>
        <li>Bisnode Finland Oy (kredituppgifter)</li>
        <li>Suomen Asiakastieto Oy (kredit- och kontouppgifter)</li>
        <li>
          Myndigheten för digitalisering och befolkningsdata och andra av
          myndigheter upprätthållna register (bl.a. adressuppgifter, bekräftande
          av personnummer)
        </li>
        <li>
          Websidor för jämförelsetjänster av lån och förmedling av dessa (såsom
          Lendo, kunduppgifterna fås från ansökan)
        </li>
        <li>Signicat Ab (identifiering)</li>
        <li>Onfido (identifiering)</li>
        <li>Tink (kontouppgifter)</li>
      </ul>

      <h3>8. Regelmässigt utlämnande av uppgifter</h3>
      <p>
        I princip lämnas uppgifterna inte ut till utomstående, frånsett de
        företag som vi samarbetar med såsom kreditupplysningsföretag.
        Uppgifterna kan vid behov även ges till indrivningsbyråer och
        myndigheter.
      </p>
      <p>
        Kundregistrets uppgifter om krediter och dess skötsel kan överlåtas till
        annan kreditgivare på uppdrag av kunden och med dennes samtycke.
        Uppgifterna överlåts genom det förfrågningssystem som upprätthålls av
        Suomen Asiakastieto Oy.
      </p>
      <p>
        Uppgifter kan lämnas ut i marknadsförings- eller undersökningssyfte om
        kunden har gett sitt samtycke. Ett samtycke givet för marknadsförings
        och undersökningssyfte kan alltid annulleras.
      </p>
      <p>
        Uppgifter lämnas regelmässigt ut till vår egen marknadsföring, för detta
        syfte används e-postadresser och för- och efternamn.
      </p>

      <h3>9. Överföring av uppgifter utanför EU eller EES</h3>
      <p>
        Vi överför i allmänhet inte personuppgifter utanför EU eller EES
        området. Om det är nödvändigt att föra över personuppgifter utanför EU
        eller EES för att tillhandahålla tjänsten, försäkrar vi oss om att
        personuppgifterna har ett tillräckligt skydd bland annat genom att komma
        överens om att personuppgifterna behandlas konfidentiellt och lagenligt
        i enlighet med till exempel de standardavtalsklausuler som Europeiska
        Kommissionen har godkänt och i övrigt genom att personuppgifter
        behandlas enligt denna dataskyddspraxis.
      </p>

      <h3>10. Profilering och automatiskt beslutsfattande</h3>
      <p>
        Vid behandling av personuppgifter använder vi oss av både automatiskt
        beslutsfattande och profilering. Detta är nödvändigt för ett
        kreditbeslut.
      </p>
      <p>
        Med profilering avser vi till exempel en analys för att förutse en kunds
        ekonomiska situation, intressen, pålitlighet, beteende, placering eller
        rörelser.
      </p>
      <p>
        Automatiskt beslutsfattande innebär att ett kreditbeslut görs
        automatiskt på basen av de uppgifter som finns i ansökan utan manuell
        behandling. Kreditbeslutet baseras på en profilering där låneansökandens
        personliga egenskaper och den information som erhållits från olika
        källor såsom kreditklassificering, betalningsanmärkningar, skulder,
        inkomster, utgifter och andra motsvarande uppgifter påverkar.
      </p>
      <p>
        Den registrerade har i vissa fall rätt att få ett negativt kreditbeslut
        omprövat av en fysisk person. Denna rätt gäller inte i sådana fall att
        kreditbeslutet gjorts på basen av en betalningsanmärkning eller någon
        annan motsvarande uppgift.
      </p>

      <h3>11. Principer för skydd av registret</h3>
      <p>
        De kunduppgifter som finns i pappersform förvaras i låsta och övervakade
        lokaler. Kundregisteruppgifterna finns i en databas som är skyddad av en
        brandvägg, de är dolda bakom användarnamn och skyddade med hjälp av
        andra tekniska lösningar. Endast Fixura Ab Oy:s personal eller
        befullmäktigade samarbetsparter har användarrättigheter till
        uppgifterna. Fixura Ab Oy kräver tystnadsplikt av dess personal och
        samarbetsparter.
      </p>

      <h3>12. Kontrollrätt</h3>
      <p>
        Enligt personuppgiftslagen har varje registrerad person rätt att
        kontrollera anteckningar om personen i fråga i personregistret. En
        begäran lämnas in skriftligen till adressen{' '}
        <a href="mailto:info@fixura.com">info@fixura.com</a>.
      </p>

      <h3>13. Rätt att yrka på rättelse av uppgifter</h3>
      <p>
        Den registrerade har rätt att yrka på att en felaktig uppgift korrigeras
        eller avlägsnas. En del av uppgifterna som användaren har angett kan hen
        själv ändra via sin egen profil. De inkorrekta uppgifter som den
        registrerade användaren själv kan ändra måste omedelbart ändras på eget
        initiativ när uppgifterna inte längre är korrekta. Ifall användaren inte
        kan ändra sina uppgifter bör hen kontakta Fixuras kundsupport för att
        ändra uppgifterna. Den registrerade är även själv ansvarig för att
        upprätthålla sina uppgifter, bland annat kontaktuppgifter.
      </p>

      <h3>14. Rätt att radera uppgifter samt att begränsa dess användning</h3>
      <p>
        Behandling av en registrerad persons personuppgifter grundar sig
        huvudsakligen på avtal (allmän dataskyddsförordning, artikel 6.1). I en
        sådan situation har personen i fråga i princip inte rätt att avlägsna
        eller begränsa sina egna uppgifter.
      </p>
      <p>
        När den registrerade har gett sitt samtycke till direktmarknadsföring,
        kan hen även annullera användningen av sina uppgifter för
        direktmarknadsföring. Ett samtycke kan annulleras genom att logga in på
        sitt konto eller genom att ta kontakt med Fixuras kundsupport
        skriftligen.
      </p>

      <h3>15. Andra rättigheter som hör till behandling av personuppgifter</h3>
      <p>
        Den registrerade har rätt att besvära sig hos Dataskyddsmyndigheten
        ifall hen anser att Fixura som registeransvarig inte har följd denna
        dataskyddsbeskrivning eller lagenlig praxis.
      </p>
    </div>
  )
}

export default PrivacyPolicy
