import { CustomInput } from 'reactstrap'
import React from 'react'

interface Props {
  value: number
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  id: string
  step: number
  min: number
  max: number
}

const RangeInput = (props: Props) => {
  const { value, handleChange, step, min, max, id } = props

  return (
    <CustomInput
      id={id}
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
    />
  )
}

export default RangeInput
