import React from 'react'
import twitter from '../../assets/images/social/twitter.svg'
import facebook from '../../assets/images/social/facebook.svg'
import linkedin from '../../assets/images/social/linkedin.svg'

import { Container, Row, Col } from 'reactstrap'
import { WithTranslation } from 'react-i18next'
import FixuraLogo from '../shared/FixuraLogo'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import ic_headset_white from 'assets/images/ic_headset_white.png'
import ic_email_white from 'assets/images/ic_email_white.png'
import { useLocation } from 'react-router'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Footer = (props: Props) => {
  const { language, t } = props

  const lang = language === 'se' ? 'sv' : language

  const location = useLocation()
  const isLogin =
    location.pathname.includes('login') ||
    location.pathname.includes('register')

  const isInvestor =
    location.pathname.includes('investor') ||
    location.pathname.includes('company')

  return (
    <footer>
      <section className={isLogin ? 'footer-top sm-hide' : 'footer-top'}>
        <Container className="py-md-3">
          <Row className="mb-lg-3 mb-md-3">
            <Col md="12" className="footer-first-row">
              <div className="sm-mt-1 footer-first-row__item sm-mb-3">
                <FixuraLogo size={'large'} color={'white'} />
              </div>
              <div className="footer-first-row__item sm-mb-3">
                <img
                  src={ic_headset_white}
                  alt={t('PHONE')}
                  className="footer-icon"
                />
                {isInvestor ? (
                  <a className="footer-bold-text" href="tel:+358 6 361 2112">
                    +358 6 361 2112
                  </a>
                ) : (
                  <a className="footer-bold-text" href="tel:+358 6 361 2111">
                    +358 6 361 2111
                  </a>
                )}
              </div>
              <div className="footer-first-row__item sm-mb-3">
                <img
                  src={ic_email_white}
                  alt={t('EMAIL')}
                  className="footer-icon"
                />
                <a className="footer-bold-text" href="mailto:info@fixura.com">
                  info@fixura.com
                </a>
              </div>
              <div className="sm-mb-3">
                <a
                  className="mr-3"
                  href="https://www.facebook.com/fixura.fi/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="footer-last-row__icon"
                    src={facebook}
                    alt="Facebook"
                  />
                </a>

                <a
                  className="mr-3"
                  href="https://twitter.com/fixura"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="footer-last-row__icon"
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/fixura-ab-oy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="footer-last-row__icon"
                    src={linkedin}
                    alt="Linkedin"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={isLogin ? 'footer-bottom sm-hide' : 'footer-bottom'}>
        <Container className="py-2 px-0">
          <Row className="m-0">
            <Col md="12">
              <p>© Fixura Ab Oy 2021</p>
              {/* eslint-disable-next-line */}
              {lang !== 'fi' ? (
                <a
                  href={`https://fixura.com/${lang}/${t('POLICY_INFO_LINK')}/`}
                >
                  {t('FOOTER_PRIVACY_POLICY')}
                </a>
              ) : (
                <a href={`https://fixura.com/${t('POLICY_INFO_LINK')}/`}>
                  {t('FOOTER_PRIVACY_POLICY')}
                </a>
              )}
              {/* eslint-disable-next-line */}
              {lang !== 'fi' ? (
                <a
                  href={`https://fixura.com/${lang}/${t(
                    'CONSUMER_INFO_LINK'
                  )}/`}
                >
                  {t('FOOTER_CONSUMER_INFORMATION')}
                </a>
              ) : (
                <a href={`https://fixura.com/${t('CONSUMER_INFO_LINK')}/`}>
                  {t('FOOTER_CONSUMER_INFORMATION')}
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section className={isLogin ? 'sm-footer-contact' : 'hide'}>
        <Container className="px-0 text-center">
          <Row className="m-0">
            <Col md="12">
              {/* eslint-disable-next-line */}
              {lang !== 'fi' && lang !== 'sv' ? (
                <a href={`https://fixura.com/${lang}/about-us/`}>
                  {t('CONTACT_US')}
                </a>
              ) : lang === 'sv' ? (
                <a href={`https://fixura.com/sv/om-oss/`}>{t('CONTACT_US')}</a>
              ) : (
                <a href={`https://fixura.com/fixura/`}>{t('CONTACT_US')}</a>
              )}
              <p>© Fixura Ab Oy 2021</p>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  )
}

export default Footer
