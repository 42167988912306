import React, { useState } from 'react'
import { InstalmentModel } from './Instalment.model'
import { Col, Row } from 'reactstrap'
import { formatMoney } from '../../../../utils'
import InvoiceStatus from './InvoiceStatus'
import InvoiceTypeEnum from './InvoiceType.enum'
import InstalmentMoreInfoRow from './InstalmentMoreInfoRow'
import { useTranslation } from 'react-i18next'

interface Props extends InstalmentModel {
  downloadInvoice: (invoiceId: number | string, legacyUserId?: number) => void
}

const Instalment = (props: Props) => {
  const {
    dueDate,
    invoiceStatus,
    totalAmount,
    interestRate,
    downloadInvoice,
    invoiceId,
    legacyUserId,
    invoiceTotalAmount,
    invoicePaidAmount,
    interest,
    fee,
    principal,
    penalty,
    discount,
  } = props

  const { t } = useTranslation()

  const onClickInvoice = () => {
    if (invoiceStatus === InvoiceTypeEnum.INVOICED && invoiceId) {
      downloadInvoice(invoiceId, legacyUserId)
    }
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const showProperty = () => !!(!interest || principal || fee || penalty)
  return (
    <div
      className={`col-xl-9 col-lg-9 col-md-9 mx-auto payment-plan__main ${getClassName(
        invoiceStatus
      )}`}
      onClick={toggleIsOpen}
    >
      <Row className="payment-plan__row">
        <Col className="payment-plan_items" xs="4" lg="3" md="3" sm="3">
          {dueDate}
        </Col>
        {invoiceTotalAmount && invoicePaidAmount ? (
          <>
            <Col className="payment-plan_items" lg="1" xs="3">
              <div>{formatMoney(invoiceTotalAmount)}€</div>
              <div className="d-lg-none">{formatMoney(invoicePaidAmount)}€</div>
            </Col>
            <Col
              className="d-none d-lg-block payment-plan_items payment-plan_items__paid"
              lg="2"
            >
              {formatMoney(invoicePaidAmount)}€
            </Col>
          </>
        ) : (
          <Col className="payment-plan_items" xs="3" lg="3">
            {formatMoney(totalAmount)}€
          </Col>
        )}
        <Col className="payment-plan_items d-none d-sm-block" xs="2" lg="2">
          {interestRate ? interestRate : '-'}%
        </Col>
        <Col
          xs="2"
          md="3"
          lg="3"
          className="payment-plan_items"
          onClick={onClickInvoice}
        >
          <InvoiceStatus invoiceStatus={invoiceStatus} />
        </Col>
        <Col
          onClick={onClickInvoice}
          className="payment-plan_items"
          xs="2"
          md="1"
        >
          {!isOpen ? (
            <button
              className={`btn btn-link payment-plan__dropdown-button h-auto ${dropdownButtonDisplay(
                showProperty()
              )}`}
              onClick={toggleIsOpen}
            >
              ►
            </button>
          ) : (
            <button
              className="btn btn-link payment-plan__dropdown-button h-auto"
              onClick={toggleIsOpen}
            >
              ▼
            </button>
          )}
        </Col>
      </Row>
      {isOpen && (
        <>
          <InstalmentMoreInfoRow
            title={t('PRINCIPAL')}
            amount={principal}
            invoiceStatus={invoiceStatus}
          />
          <InstalmentMoreInfoRow
            title={t('INTEREST')}
            amount={interest}
            invoiceStatus={invoiceStatus}
          />
          <InstalmentMoreInfoRow
            title={t('FEE')}
            amount={fee}
            invoiceStatus={invoiceStatus}
          />
          <InstalmentMoreInfoRow
            title={t(
              '1ST_YEARS_LOAN_ACCOUNT_FEE_WAS_CHARGED_WHEN_LOAN_WAS_TAKEN'
            )}
            amount={discount}
            invoiceStatus={invoiceStatus}
          />
          <InstalmentMoreInfoRow
            title={t('PENALTY')}
            amount={penalty}
            invoiceStatus={invoiceStatus}
          />
        </>
      )}
    </div>
  )
}

function getClassName(invoiceStatus: InvoiceTypeEnum) {
  switch (invoiceStatus) {
    case InvoiceTypeEnum.READY_FOR_INVOICING:
      return 'up-coming'
    case InvoiceTypeEnum.PARTIALLY_PAID:
    case InvoiceTypeEnum.INVOICED:
      return 'current'
    default:
      return 'completed'
  }
}

function dropdownButtonDisplay(showProperty: boolean) {
  if (!showProperty) {
    return 'no-display'
  }
}

export default Instalment
