import queryString from 'query-string'
import browserHistory from 'browserHistory'

export const broker = () => {
  const url = browserHistory.location.search
  const qs = queryString.parse(url.split('?')[1]) || ''
  if (browserHistory.location.pathname.includes('login/bank')) {
    return qs
  }
}

export const brokerLoanApplicationId = () => {
  const data = broker()
  if (data) {
    const utm = {
      fromBroker: data.fromBroker,
      blaId: data.blaId,
    }

    return utm
  }
}
