export const STORE_LOAN_APPLICATION_REQUEST =
  'user.borrower.loan_application.STORE_LOAN_APPLICATION_REQUEST'
export const STORE_LOAN_APPLICATION_SUCCESS =
  'user.borrower.loan_application.STORE_LOAN_APPLICATION_SUCCESS'
export const STORE_LOAN_APPLICATION_FAILURE =
  'user.borrower.loan_application.STORE_LOAN_APPLICATION_FAILURE'

export const AISP_RETRY_REQUEST =
  'user.borrower.loan_application.AISP_RETRY_REQUEST'
export const AISP_RETRY_SUCCESS =
  'user.borrower.loan_application.AISP_RETRY_SUCCESS'
export const AISP_RETRY_FAILURE =
  'user.borrower.loan_application.AISP_RETRY_FAILURE'

export const GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST =
  'user.borrower.loan_application.GET_CHECK_LOAN_APPLICATION_STATUS_REQUEST'
export const GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS =
  'user.borrower.loan_application.GET_CHECK_LOAN_APPLICATION_STATUS_SUCCESS'
export const GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE =
  'user.borrower.loan_application.GET_CHECK_LOAN_APPLICATION_STATUS_FAILURE'

export const GET_ACTIVE_LOANS_SUCCESS =
  'user.borrower.loan_application.GET_ACTIVE_LOANS_SUCCESS'
export const GET_ACTIVE_LOANS_REQUEST =
  'user.borrower.loan_application.GET_ACTIVE_LOANS_REQUEST'
export const GET_ACTIVE_LOANS_FAILURE =
  'user.borrower.loan_application.GET_ACTIVE_LOANS_FAILURE'

export const UPDATE_LOAN_APPLICATION_REQUEST =
  'user.borrower.loan_offer.UPDATE_LOAN_APPLICATION_REQUEST'
export const UPDATE_LOAN_APPLICATION_SUCCESS =
  'user.borrower.loan_offer.UPDATE_LOAN_APPLICATION_SUCCESS'
export const UPDATE_LOAN_APPLICATION_FAILURE =
  'user.borrower.loan_offer.UPDATE_LOAN_APPLICATION_FAILURE'

export const FETCH_BANKS_REQUEST =
  'user.borrower.loan_offer.FETCH_BANKS_REQUEST'
export const FETCH_BANKS_SUCCESS =
  'user.borrower.loan_offer.FETCH_BANKS_SUCCESS'
export const FETCH_BANKS_FAILURE =
  'user.borrower.loan_offer.FETCH_BANKS_FAILURE'

export const CREATE_BANK_SESSION_REQUEST =
  'user.borrower.loan_offer.CREATE_BANK_SESSION_REQUEST'
export const CREATE_BANK_SESSION_SUCCESS =
  'user.borrower.loan_offer.CREATE_BANK_SESSION_SUCCESS'
export const CREATE_BANK_SESSION_FAILURE =
  'user.borrower.loan_offer.CREATE_BANK_SESSION_FAILURE'

export const POST_TINK_CODE_REQUEST =
  'user.borrower.loan_offer.POST_TINK_CODE_REQUEST'
export const POST_TINK_CODE_SUCCESS =
  'user.borrower.loan_offer.POST_TINK_CODE_SUCCESS'
export const POST_TINK_CODE_FAILURE =
  'user.borrower.loan_offer.POST_TINK_CODE_FAILURE'

export const UPDATE_IS_CHECKED_CCIS_REQUEST =
  'user.borrower.loan_offer.UPDATE_IS_CHECKED_CCIS_REQUEST'
export const UPDATE_IS_CHECKED_CCIS_SUCCESS =
  'user.borrower.loan_offer.UPDATE_IS_CHECKED_CCIS_SUCCESS'
export const UPDATE_IS_CHECKED_CCIS_FAILURE =
  'user.borrower.loan_offer.UPDATE_IS_CHECKED_CCIS_FAILURE'

export const TOGGLE_IS_CHECKED_CCIS =
  'user.borrower.loan_offer.TOGGLE_IS_CHECKED_CCIS'
