import React from 'react'

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>Fixura Ab Oy Privacy Policy </h3>
      <p>
        This privacy policy describes how we collect and use your personal
        information. We will also tell you about your rights and how you can use
        them. We are committed to protecting the privacy of our users in
        accordance with data protection laws and other applicable laws in
        Finland. It is important that you read and understand our privacy policy
        before using our services.
      </p>

      <h3>Key concepts</h3>
      <p>
        Personal data means any information related to an identified or
        identifiable natural person. Personal information can be information
        such as name, address and phone number. Other kind of information, such
        as your IP address can also be classified as personal information.
      </p>
      <p>
        Processing of personal data means all processing of personal data like
        the collection, analysis, modification and storage of personal data.
      </p>
      <p>
        Controller means the entity that defines the purposes and means of
        processing personal data. The controller is also responsible for
        ensuring that personal data is being processed in accordance with
        applicable law.
      </p>
      <p>
        Registered person means the user that has registered as a user for
        Fixura services.
      </p>

      <h3>1. In charge of the register</h3>
      <p>
        Fixura Ab Oy <br />
        Business ID 2246639-7
        <br />
        Hovioikeudenpuistikko 15 C 29, 65100 Vaasa, Finland
        <br />
        Telephone +358-(0)20 7344 530
        <br />
        e-mail: <a href="mailto:info@fixura.com">info@fixura.com</a>
      </p>

      <h3>2. Contact in register matters</h3>
      <p>
        Inquiries and request for verification of personal data is sent to the
        address <a href="mailto:info@fixura.com">info@fixura.com</a>
        <br />
      </p>

      <h3>3. The name of the register</h3>
      <p>The customer register of Fixura Ab Oy.</p>

      <h3>4. The objective of the handling of personal data</h3>
      <p>
        The objective of handling of personal data is to take care of and to
        develop assignments and services regarding the Fixura Ab Oy register and
        for example to determine loan classification, to administrate contact
        data, make risk assessments that cover for example activities against
        money laundering, direct marketing and to serve customers and to take
        care of duties according to law such as storing, reporting and taking
        care of enquiries according to authority directives and instructions.
      </p>
      <p>
        Furthermore, personal data is handled according to what the law allows
        and obliges to handle customer contacts, for administration and
        development, analysis, statistics and to produce and offer information
        services.
      </p>
      <p>
        Fixura Ab Oy and the companies of the same group have the right to use
        and to distribute data of the register for certain purposes, for example
        for marketing purposes according to the Personal Data Act. During the
        registration process the person may accept the use of personal data for
        marketing purposes.
      </p>

      <h3>5. Data contents of the register</h3>
      <p>The register may contain data about groups of the following kind:</p>
      <ul>
        <li>
          The registered person has or has had a customer relationship, made an
          application to start a customer relationship, registered a customer
          relationship or some other personal contact with the holder of the
          register.
        </li>
        <li>
          The registered person is or has been a party in a customer
          relationship, has or has had an obligation or some other entitlement
          to agreement with or to service from the holder of the register or an
          entitlement to give an assignment to the holder of the register.
        </li>
        <li>
          Registration of data is based on an obligation according to law.
        </li>
      </ul>

      <h3>Basic personal data such as</h3>
      <ul>
        <li>
          Name, address, postal code, postal address, municipality of residence,
          phone number, banking data, e-mail address
        </li>
        <li>The date when the customer relationship started and ended</li>
        <li>Date of birth and personal identity code/business ID</li>
        <li>Language, nationality</li>
        <li>Country of taxation</li>
        <li>
          Information about the financial situation of the customer (for example
          education, profession, income data)
        </li>
        <li>Consent to or prohibition of direct marketing</li>
        <li>IP-addresses</li>
        <li>
          Borrower data (username, loan applications, loan agreements,
          invoicing, debt collection, payment defaults)
        </li>
        <li>
          Investor data (username, agreements, data about the investor, such as
          investment experience, basic data about company users such as company
          name, business ID, contact person)
        </li>
        <li>
          Legal engagements and commitments (for example politically influential
          person)
        </li>
      </ul>

      <p>
        The customer consent is specifically given to obtain following data:
      </p>
      <ul>
        <li>Bank account information</li>
        <li>
          Information obtained from the Consumer Credit Inquiry System (CCIS)
          maintained by Suomen Asiakastieto Oy
        </li>
      </ul>
      <p>
        With the specific consent from the customer, the customer's bank account
        transactions for the last 12 months are stored in the customer register
        for analysis in connection with the credit application.
      </p>
      <p>
        Information on the customers previous credits is stored in the customer
        register. The information is obtained during the credit application
        process from other credit grantors in the CCIS maintained by Suomen
        Asiakastieto Oy.”
      </p>

      <h3>6. Storing of personal data</h3>
      <p>
        Data that has been collected about the registered person is stored in
        the customer register for five years after the loans or investments have
        been repaid in full. Upon request the user account can be inactivated
        during the data retention period and the account can then no longer be
        used. However, specific information i.e. transaction details, will be
        stored from the minimum of 10 years according to Accounting Act.
      </p>
      <p>
        If the registered person does not have an active loan or active
        investments the data will be kept for two years after the last
        registration, log in, application or other activity.
      </p>

      <h3>7. Regular sources of information</h3>
      <p>
        Data is collected about the user at registration as well as during the
        whole duration of the customer relationship. Personal data can be
        collected and/or verified from the following sources:
      </p>
      <ul>
        <li>
          From the customer at registration or later when they complement or
          change their data
        </li>
        <li>Bisnode Finland Oy (credit defaults)</li>
        <li>
          Suomen Asiakastieto Oy (credit defaults, bank account information,
          CCIS)
        </li>
        <li>From authority registers (address, social security number)</li>
        <li>Broker and affiliate pages (loan application information)</li>
        <li>Signicat Ab (identification)</li>
        <li>Onfido (identification)</li>
        <li>Tink (bank account information)</li>
      </ul>

      <h3>8. Regular distribution of data</h3>
      <p>
        Data can be distributed to outsiders such as credit information
        companies, collection agencies, other possible cooperation partners and
        authorities. The data required for credit analysis based on analysis of
        the borrower's online banking transactions can be collected and
        disclosed to current partners for data analysis, evaluation, service
        development as improving the performance of the data entry recognition,
        categorization, sorting and behavior factor generation functionality.
      </p>
      <p>
        Information on credits and payment behaviour can be disclosed to other
        credit grantors on the basis of the customer’s assignment and consent.
        Information is disclosed by means of an inquiry system (CCIS) maintained
        by Suomen Asiakastieto Oy.”
      </p>
      <p>
        If the customer has given their consent, data can be used and
        distributed for marketing or research purposes.
      </p>
      <p>
        Data is regularly distributed to the company´s own marketing where
        e-mail addresses and first and last name are used. This information is
        not distributed to external parties.
      </p>

      <h3>9. Distribution of information outside the EU or the EEA</h3>
      <p>
        Fixura does not generally distribute personal data outside the EU and
        EEA. If it is necessary to distribute personal data outside the EU and
        EEA to provide the service, we ensure that the personal data is
        sufficiently protected for example by agreeing that the personal data is
        treated confidentially and lawfully according to for example the
        standard contractual clause that the European Commission has approved
        and otherwise by handling personal data according to this privacy
        policy.
      </p>

      <h3>10. Profiling and automated individual decision making</h3>
      <p>
        Automatic decision making related and profiling are being applied to the
        processing of personal data. This is an essential part of the credit
        decision making.
      </p>
      <p>
        Profiling refers to analyzing or forecasting features and
        characteristics related for example to the customers economic situation,
        behavior, location or movements.
      </p>
      <p>
        Automatic decision-making means that the credit decision is made
        automatically without any human interaction or manual handling. The loan
        decision is based on profiling together with analyzes of information
        from various sources like payment defaults, income, expenditure, debts
        and other similar information.
      </p>
      <p>
        When the credit decision is negative, the borrower has the right to
        request for manual handling of the loan application. However, this right
        cannot be exercised for credit decisions that have been denied based on
        the applicant´s credit default or other similar reasons.
      </p>

      <h3>11. Principles for protection of the Register</h3>
      <p>
        The customer data in writing is being kept in a locked and monitored
        space. The customer register data is in a database behind a firewall,
        protected usernames and by other technical solutions. Only the staff of
        Fixura Ab Oy or authorized partners have access to the data. The staff
        of Fixura Ab Oy and partners are bound by confidentiality.
      </p>

      <h3>12. The right of inspection</h3>
      <p>
        According to law each registered person has the right to inspect all
        entries made about themselves in the personal register. The inspection
        application should be submitted in writing and be addressed to the
        holder of the register to the address{' '}
        <a href="mailto:info@fixura.com">info@fixura.com</a>.
      </p>

      <h3>13. The right to require correction of data</h3>
      <p>
        The registered person has the right to require that incorrect data is
        corrected or removed. Part of the data that the user has submitted can
        be changed through the personal user profile in the system. The data
        that the registered user may change immediately has to be corrected on
        the user´s own initiative when the data is no longer correct. If the
        user cannot change their data, the user should contact Fixura customer
        support to change the data.
      </p>

      <h3>14. Right to erasure and right to restriction of processing</h3>
      <p>
        Handling of the personal data of a registered person is based on an
        agreement. It means that these persons do not have the right to remove
        their own data.
      </p>
      <p>
        The registered person is entitled to deny the use of their data for
        marketing purposes by contacting the Fixura customer support in writing
        or by logging in to the personal user profile.
      </p>

      <h3>15. Other rights in connection with handling of personal data</h3>
      <p>
        The registered individual has the right to make a complaint to the
        Privacy protection authority in Finland if they are of the opinion that
        Fixura as the holder of the register has acted against this privacy
        policy or the existing legal praxis.
      </p>
    </div>
  )
}

export default PrivacyPolicy
