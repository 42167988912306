import API from '../API'
import LoanCalculationRequest from '../../models/Borrower/LoanCalculationRequest'
import StoreLoanApplicationRequest from '../../models/Borrower/StoreLoanApplicationRequest'
import UpdateLoanOfferRequest from '../../models/Borrower/UpdateLoanOfferRequest'

export default class BorrowerAPI extends API {
  public getBorrowerLoan(loanId: string) {
    return this.get(`api/borrower/loan/${loanId}`)
  }

  public getLoanContract(LoanId: number) {
    return this.get(`api/document/borrower/${LoanId}`)
  }

  public loanCalculation(data: LoanCalculationRequest) {
    return this.post(
      'faircreditcalc/front-end-faircredit',
      data,
      {},
      'https://stats-api.fixura.com'
    )
  }

  public storeLoanApplication(data: StoreLoanApplicationRequest) {
    return this.post('api/borrower/loan-application', data)
  }

  public aispRetry(loanApplicationId: number) {
    return this.get(
      `api/borrower/loan-application/${loanApplicationId}/aisp/redirect`
    )
  }

  public checkLoanStatus(loanApplicationId: number) {
    return this.get(
      `api/borrower/loan-application/${loanApplicationId}/scoring/check`
    )
  }

  public updateLoanApplication(
    loanApplicationId: number,
    data: UpdateLoanOfferRequest
  ) {
    return this.patch(
      `api/borrower/loan-application/${loanApplicationId}`,
      data
    )
  }

  public storeLoan(loanApplicationId: number) {
    return this.post('api/borrower/loan', {
      loan_application_id: loanApplicationId,
    })
  }

  public getActiveLoanApplication() {
    return this.get('api/borrower/loan-application/active')
  }

  public getBanks(loanApplicationId: number) {
    return this.get(
      `api/borrower/loan-application/${loanApplicationId}/aisp/bank`
    )
  }

  public createBankSession(loanApplicationId: number, bankId) {
    return this.post(
      `api/borrower/loan-application/${loanApplicationId}/aisp/session`,
      {
        bank_id: bankId,
      }
    )
  }

  public postTinkCode(loanApplicationId: number, code: string) {
    return this.post(
      `api/borrower/loan-application/${loanApplicationId}/aisp/transactions`,
      {
        code,
      }
    )
  }

  public updateIsCheckedCCIS(
    loanApplicationId: number,
    isCheckedCcis: boolean
  ) {
    return this.patch(
      `/api/borrower/loan-application/${loanApplicationId}/consent`,
      {
        is_checked_ccis: isCheckedCcis,
      }
    )
  }

  public getBrokerLoanApplicationRequest(blaId) {
    return this.get(`api/borrower/loan-application/loan-detail/${blaId}`)
  }

  public getTotalLoanAmtLeft() {
    return this.get('api/borrower/dashboard/all-loans-amount-left')
  }
}
