import React from 'react'
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import browserHistory from '../../../../browserHistory'
import { WithTranslation } from 'react-i18next'
import MappedStateModel from './MappedState.model'
import MappedDispatchModel from './MappedDispatch.model'
import { useParams } from 'react-router-dom'
import { formatMoney } from '../../../../utils'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const Presentation = (props: Props) => {
  const { amount, withdraw, t, depositId, bankAccounts, data } = props
  const { userId: legacyUserId } = useParams()
  const investorBankAccount = bankAccounts && bankAccounts[0]?.IBAN

  const submitData = () => {
    if (depositId) {
      withdraw(depositId)
    } else {
      withdraw(legacyUserId)
    }
    browserHistory.goBack()
  }

  if (!amount || amount === 0) {
    browserHistory.goBack()
  }

  const isAmountGreaterThanFreeCapital = amount > data.free_capital
  const amountAutoSell = amount - data.free_capital
  const autoSellFee = (amount - data.free_capital) / 100

  return (
    <Container>
      <Row>
        <Col xs="12">
          <Modal
            className="wd-modal"
            isOpen={true}
            toggle={browserHistory.goBack}
          >
            <ModalHeader className="p-0" toggle={browserHistory.goBack} />
            <ModalHeader className="center p-0">
              {t('CONFIRM_WITHDRAW_OF')} {formatMoney(amount)} €
            </ModalHeader>
            <ModalBody>
              {!isAmountGreaterThanFreeCapital ? (
                <>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('REQUESTED_AMOUNT', {
                        accountNumber: investorBankAccount,
                      })}
                    </strong>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('WITHDRAW_CONFIRM_PRODUCT_21_DETAILS', {
                        accountNumber: investorBankAccount,
                        amount: formatMoney(amountAutoSell),
                      })}
                    </strong>
                  </div>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('WITHDRAW_CONFIRM_PRODUCT_21_FREE_CAPITAL')}
                    </strong>
                    &nbsp;
                    <strong className="confirm-dialog-body-text">
                      {formatMoney(data.free_capital)} €
                    </strong>
                  </div>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('WITHDRAW_CONFIRM_PRODUCT_21_AUTOSELL')}
                    </strong>
                    &nbsp;
                    <strong className="confirm-dialog-body-text">
                      {formatMoney(amountAutoSell)} €
                    </strong>
                  </div>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('WITHDRAW_CONFIRM_PRODUCT_21_AUTOSELL_FEE')}
                    </strong>
                    &nbsp;
                    <strong className="confirm-dialog-body-text">
                      {formatMoney(autoSellFee)} €
                    </strong>
                  </div>
                  <div>
                    <strong className="confirm-dialog-body-text">
                      {t('WITHDRAW_CONFIRM_PRODUCT_21_TRANSFER')}
                    </strong>
                    &nbsp;
                    <strong className="confirm-dialog-body-text">
                      {formatMoney(amount - autoSellFee)} €
                    </strong>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={submitData}>
                {t('WITHDRAW_AMOUNT', {
                  amount: formatMoney(amount),
                })}
              </Button>
              <Button
                className="mt-2 cancel-btn"
                onClick={browserHistory.goBack}
              >
                {t('CLOSE')}
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
  )
}

export default Presentation
