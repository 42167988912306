import React from 'react'
import { Route, Switch } from 'react-router'
import ViewOffer from '../components/Borrower/LoanApplication/LoanOffer'
import SignContract from '../components/Borrower/LoanApplication/SignContract'
import ThankYou from '../components/Borrower/LoanApplication/ThankYou'
import Rejected from '../components/Borrower/LoanApplication/Rejected'
import Dashboard from '../components/Borrower/Dashboard'
import GatherInformation from '../components/Borrower/AISP/GatherInformation'
import LoanApplication from './LoanApplication'
import LoanGuardRoute from './guards/LoanGuardRoute'
import Reject from '../components/Borrower/AISP/Reject'
import AispRejectionGuardRoute from './guards/LoanApplication/AispRejectionGuardRoute'
import CCIS from '../components/Borrower/CCIS/CCIS'
import CCISModal from '../components/Borrower/CCIS/CCISModal'
import CCISGuardRoute from './guards/LoanApplication/CCISGuardRoute'

export default () => (
  <div id="container">
    {/* @ts-ignore */}
    <LoanGuardRoute exact path="/dashboard/borrower">
      <Dashboard />
    </LoanGuardRoute>
    <Route path="/dashboard/borrower/fixed-rate-loan/:id">
      <Dashboard />
    </Route>
    <Route path="/dashboard/borrower/fair-credit-loan/:id">
      <Dashboard />
    </Route>
    <Route path="/dashboard/borrower/aisp">
      <GatherInformation />
    </Route>
    {/* @ts-ignore */}
    <AispRejectionGuardRoute path="/dashboard/borrower/aisp-rejected">
      <Reject />
    </AispRejectionGuardRoute>
    <Route path="/dashboard/borrower/view-offer">
      <ViewOffer />
    </Route>
    <Route path="/dashboard/borrower/sign-contract">
      <SignContract />
    </Route>
    <Route path="/dashboard/borrower/signed">
      <ThankYou />
    </Route>
    <Route path="/dashboard/borrower/loan-rejected">
      <Rejected />
    </Route>
    <Route path="/dashboard/borrower/loan-application">
      <LoanApplication />
    </Route>
    {/* @ts-ignore */}
    <CCISGuardRoute path="/dashboard/borrower/ccis">
      <CCISView />
    </CCISGuardRoute>
  </div>
)

const CCISView = () => (
  <>
    <CCIS />
    <Switch>
      <Route path="/dashboard/borrower/ccis/more">
        <CCISModal />
      </Route>
    </Switch>
  </>
)
