import React, { FunctionComponent } from 'react'
import { Input } from 'reactstrap'
import { connect } from 'react-redux'
import { State } from '../../../store'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { createToggleIsCheckedCCISAction } from '../../../store/user/borrower/loan_application/actions/creators'
import { Dispatch } from 'redux'

interface Props extends MappedState, MappedDispatch {}

const CheckBoxCCIS: FunctionComponent<Props> = (props: Props) => {
  const { is_checked_ccis, toggleIsCheckedCCIS } = props
  const location = useLocation()

  const { t } = useTranslation()

  return (
    <div className="ccis-box bank">
      <Input
        className="terms-and-conditions__checkbox__color"
        type="checkbox"
        onChange={toggleIsCheckedCCIS}
        checked={is_checked_ccis}
      />
      <div className="receive-offers__title ml-20">
        {t('CCIS_CONSENT_MODAL_TEXT')}
        <Link to={`${location.pathname}/more`}>{t('MORE')}</Link>
      </div>
    </div>
  )
}
interface MappedState {
  is_checked_ccis?: boolean
}

interface MappedDispatch {
  toggleIsCheckedCCIS: () => void
}

const mapStateToProps = (state: State): MappedState => ({
  is_checked_ccis: state.user.borrower.loan_application.is_checked_ccis,
})

const mapDispatchToProps = (dispatch: Dispatch): MappedDispatch => ({
  toggleIsCheckedCCIS: () => dispatch(createToggleIsCheckedCCISAction()),
})

export default connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(CheckBoxCCIS)
