import React from 'react'
import { useTranslation } from 'react-i18next'

const OfferInterestRateInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="text-center loan-interest-rate">
      {t('YOUR_LOAN_OFFER_INTEREST_RATE_INFORMATION')}
    </div>
  )
}

export default OfferInterestRateInformation
