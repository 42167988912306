import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { LinkedCalendar } from '../../shared/RangeDatePicker'
import dayjs from 'dayjs'
import Dates from '../../../models/RangeDatepicker/Dates'
import LabeledValue from './LabeledValue'
import { WithTranslation } from 'react-i18next'
import MappedDispatchModel from './MappedDispatch.model'
import MappedStateModel from './MappedState.model'
import { formatMoney, removeDecimal } from '../../../utils'
import { isLegacy as isLegacyUser } from '../../../utils/auth'
import { ScrollElement } from 'utils/scroll'
import { useParams } from 'react-router-dom'
import ic_info_icon_white from 'assets/images/ic_info_icon_white.svg'
import Indicator from '../../shared/Indicator'
import Toast from '../../../utils/toast'

interface Props
  extends WithTranslation,
    MappedStateModel,
    MappedDispatchModel {}

const PortfolioSummary = (props: Props) => {
  const {
    t,
    data,
    getInvestorPortfolio,
    numberOfDepositAccounts,
    startDate,
    isLegacy,
    depositId,
    isFetching,
    error,
  } = props
  const { userId: legacyUserId } = useParams()

  const [date, setDate] = useState<Dates>({
    startDate,
    endDate: dayjs(),
  })
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const onChangeCalendarDate = (date?: Dates) => {
    toggleShowCalendar()
    //Fixme: disable all non selectable dates
    if (date && date.startDate && startDate) {
      if (!(date.startDate < startDate)) {
        setDate(date)
        getInvestorPortfolio({ date }, depositId)
      }
    }
  }
  const toggleShowCalendar = () => setIsCalendarVisible(!isCalendarVisible)
  useEffect(
    () => {
      if ((depositId && numberOfDepositAccounts) || isLegacy)
        getInvestorPortfolio({ date, legacyUserId }, depositId)
    },
    // eslint-disable-next-line
    [numberOfDepositAccounts]
  )

  return (
    <div className="portfolio-general">
      <ScrollElement name="portfolio" />
      <Indicator isVisible={isFetching} />
      <Toast
        title="Alert"
        showToast={error !== null}
        text={t('SOMETHING_WENT_WRONG_PLEASE_REFRESH_THE_PAGE')}
        className="toast-alert"
      />
      <Container>
        <Row>
          <Col xs="12" className="button-placement">
            {!isLegacy && (
              <>
                <Button
                  onClick={toggleShowCalendar}
                  className="blue-button mb-4"
                >
                  {date.startDate && date.startDate.format('DD.MM.YYYY')}
                  <span className="arrow"> ► </span>
                  {date.endDate && dayjs().date() === date.endDate.date()
                    ? t('TODAY')
                    : date.endDate && date.endDate.format('DD.MM.YYYY')}
                </Button>
                <LinkedCalendar
                  initialDate={date}
                  onDatesChange={onChangeCalendarDate}
                  showDropdowns={false}
                  opens="right"
                  isVisible={isCalendarVisible}
                  name="portfolio"
                />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <LabeledValue
            title={t('PORTFOLIO_VALUE')}
            contentClassName="font-80 font-60-sm"
            src={ic_info_icon_white}
            info={t('PORTFOLIO_VALUE_DETAILS')}
          >
            {formatMoney(data.PortfolioValue)} €
          </LabeledValue>
        </Row>
        <Row>
          <LabeledValue
            title={t('AVG_ROI_SINCE_START')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('AVG_ROI_SINCE_START_DETAILS')}
          >
            {data.AvgROISinceStart === 0
              ? '0'
              : formatMoney(data.AvgROISinceStart)}{' '}
            %
          </LabeledValue>
          <LabeledValue
            title={t('ADJ_PORTFOLIO_GROWTH')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('ADJ_PORTFOLIO_GROWTH_DETAILS')}
            postion="bottom right"
          >
            {`${
              addPlusSignIfPositive(data.PortfolioGrowth) === 0
                ? '0'
                : formatMoney(data.PortfolioGrowth)
            } %`}
          </LabeledValue>
          <LabeledValue
            title={t('ACTIVE_INVESTMENTS')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('ACTIVE_INVESTMENTS_DETAILS')}
          >
            {formatMoney(data.ActiveInvestments)} €
          </LabeledValue>
          <LabeledValue
            title={t('NO_OF_ACTIVE_INVESTMENTS')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('NO_OF_ACTIVE_INVESTMENTS_DETAILS')}
            postion="bottom right"
          >
            {removeDecimal(data.NrOfActiveInvestments)}
          </LabeledValue>
          <LabeledValue
            title={t('FREE_CAPITAL')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('FREE_CAPITAL_DETAILS')}
          >
            {formatMoney(data.FreeCapital)} €
          </LabeledValue>
        </Row>
        <Row className="kpi-bottom">
          <LabeledValue
            contentClassName="font-25"
            title={t('INTEREST_PAID')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('INTEREST_PAID_DETAILS')}
          >
            {formatMoney(data.InterestPaid)} €
          </LabeledValue>
          <LabeledValue
            contentClassName="font-25"
            title={t('CAPITAL_REPAID')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('CAPITAL_REPAID_DETAILS')}
            postion="bottom right"
          >
            {formatMoney(data.RepaidCapital)} €
          </LabeledValue>
          <LabeledValue
            contentClassName="font-25"
            title={t('FEES_PAID')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('FEES_PAID_DETAILS')}
          >
            {formatMoney(data.Fees)} €
          </LabeledValue>
          <LabeledValue
            contentClassName="font-25"
            title={t('CREDIT_LOSSES')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('CREDIT_LOSSES_DETAILS')}
            postion="bottom right"
          >
            {formatMoney(data.CreditLosses)} €
          </LabeledValue>
          <LabeledValue
            contentClassName="font-25"
            title={t('DEPOSITS')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('DEPOSITS_DETAILS')}
          >
            {formatMoney(data.Deposits)} €
          </LabeledValue>
          <LabeledValue
            contentClassName="font-25"
            title={t('WITHDRAWALS')}
            xs="6"
            lg="4"
            src={ic_info_icon_white}
            info={t('WITHDRAWALS_DETAILS')}
            postion="bottom right"
          >
            {formatMoney(data.Withdrawals)} €
          </LabeledValue>
        </Row>
        {(!isLegacyUser() || isLegacy) && (
          <Row>
            {isLegacy && !isLegacyUser() && (
              <Col md="12" className="text-center title-placement">
                {t('INCOMING_TO_FAIR_CREDIT')}
              </Col>
            )}
            {isLegacy && (
              <Col className="p-2">
                <a href="https://old2.fixura.fi/fi-fi/home/">
                  {t('LOGIN_TO_FIXURA_2_0')}
                </a>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </div>
  )
}

function addPlusSignIfPositive(data: string | number) {
  if (+data > 0) {
    return `+ ${data}`
  }
  return data
}

export default PortfolioSummary
