import {
  createLoanCalculatorFailureAction,
  createLoanCalculatorRequestAction,
  createLoanCalculatorSuccessAction,
} from './creators'
import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import BorrowerAPI from '../../../../../api/user/BorrowerAPI'
import LoanCalculationRequest from '../../../../../models/Borrower/LoanCalculationRequest'

const getCalculatedLoan = (
  data: LoanCalculationRequest
): ThunkAction<any, any, any, any> => async (dispatch: Dispatch) => {
  dispatch(createLoanCalculatorRequestAction())
  try {
    const borrowerAPI = new BorrowerAPI()
    const response = await borrowerAPI.loanCalculation(data)
    dispatch(createLoanCalculatorSuccessAction(response.data))
  } catch (error) {
    dispatch(createLoanCalculatorFailureAction(error))
  }
}

export default getCalculatedLoan
