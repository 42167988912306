import { connect } from 'react-redux'
import Presentation from './Presentation'
import MappedStateModel from './MappedState.model'
import { State } from '../../../../store'
import InvoiceTypeEnum from './InvoiceType.enum'
import Instalment from '../../../../models/Borrower/Instalment'
import { formatDate } from '../../../../utils'
import MappedDispatchModel from './MappedDispatch.model'

const mapStateToProps = (state: State): MappedStateModel => {
  const { data } = state.user.borrower.loan
  if (data) {
    return {
      data: data.instalments.map((installment: Instalment) => ({
        dueDate: formatDate(installment.due_date, 'YYYY-MM-DD'),
        totalAmount: installment.total_due_amount,
        invoiceStatus: getInvoiceStatus(installment, data.next_instalment),
        invoiceId: installment.encoded_key,
        legacyUserId: 0,
        interestRate: installment.interest,
        invoiceTotalAmount: installment.total_due_amount,
        invoicePaidAmount: installment.total_paid_amount,
        interest: installment.interest_due,
        fee: installment.fees_due,
        principal: installment.principal_due,
        penalty: installment.penalty_due,
        discount: installment.discount,
      })),
      loanAccountState: data.loanAccountState,
    }
  }
  return { isHidden: true }
}

const mapDispatchToProps = (): MappedDispatchModel => ({
  downloadInvoice: (invoiceId: number | string) => console.log(invoiceId),
})

const getInvoiceStatus = (
  instalment: Instalment,
  nextInstalment?: Instalment
): InvoiceTypeEnum => {
  if (nextInstalment && nextInstalment.encoded_key === instalment.encoded_key) {
    return InvoiceTypeEnum.INVOICED
  }

  switch (instalment.state) {
    case 'PENDING':
      return InvoiceTypeEnum.READY_FOR_INVOICING
    case 'PAID':
      return InvoiceTypeEnum.PAID
    case 'PARTIALLY_PAID':
      return InvoiceTypeEnum.PARTIALLY_PAID
    case 'LATE':
      return InvoiceTypeEnum.PAID_LATE
    case 'GRACE':
      return InvoiceTypeEnum.CANCELED
    case 'RESCHEDULED':
      return InvoiceTypeEnum.READY_FOR_INVOICING
    default:
      return InvoiceTypeEnum.CANCELED
  }
}

export default connect<MappedStateModel, MappedDispatchModel, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(Presentation)
