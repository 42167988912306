import React, { useState, useEffect } from 'react'
import { Alert, Button, Col, Form, Row } from 'reactstrap'
import PhoneInput from '../../../shared/PhoneInput'
import { compose } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { loginWithSms } from '../../../../store/auth/login/sms/actions/loginWithSms'
import Indicator from '../../../shared/Indicator'
import { State } from '../../../../store'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Toast from '../../../../utils/toast'

interface Props extends WithTranslation {
  loginWithSms: (phoneNumber) => void
  isFetching: boolean
  isExpired: string
}

const LoginWithPhone = (props: Props) => {
  const location = useLocation()
  const [fields, setFields] = useState({})
  const [errors, setErrors] = useState({ mobile_phone: undefined })
  const [expiredToast, setExpiredToast] = useState(false)
  const { t, isFetching, loginWithSms, isExpired } = props
  const qs = queryString.parse(location.search)

  function loginFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (handleValidation(fields, setErrors, t)) {
      loginWithSms(fields['mobile_phone'])
    }
  }

  // Open error dialog box (toast)
  useEffect(() => {
    if (isExpired === 'expired') {
      setExpiredToast(true)
    }
  }, [isExpired])

  return (
    <>
      <Indicator isVisible={isFetching} />
      <Toast
        title="Alert"
        showToast={expiredToast}
        text={t('CODE_IS_EXPIRED_TRY_AGAIN')}
        className="toast-alert"
      />
      <Row>
        <Col lg={{ size: 8, offset: 2 }}>
          <p className="login-method__title pb-10">{t('LOGIN_PHONE_TITLE')}</p>
        </Col>
      </Row>
      <div>
        <Form onSubmit={loginFormSubmit}>
          {qs.error === 'onboarded' && (
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <Alert className="text-left" color="primary">
                  {t('ALREADY_ONBOARDED')}
                </Alert>
              </Col>
            </Row>
          )}
          <PhoneInput
            onChange={(mobile_phone: string) => {
              setFields({ ...fields, mobile_phone })
            }}
            hasError={errors.hasOwnProperty('mobile_phone')}
            errorMessage={errors['mobile_phone']}
            autoFocus
          />
          <Button
            className="mobile-phone-btn min-w-10"
            color="secondary"
            type="submit"
          >
            {t('CONTINUE')}
          </Button>
        </Form>
      </div>
    </>
  )
}

function handleValidation(fields, setErrors, t) {
  const errors = {}
  let formIsValid = true
  if (!fields.hasOwnProperty('mobile_phone')) {
    formIsValid = false
    errors['mobile_phone'] = t('CANNOT_BE_EMPTY')
  } else {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{2}[-\s.]?[0-9]{4,8}$/im
    if (!re.test(fields['mobile_phone'].toLowerCase())) {
      formIsValid = false
      errors['mobile_phone'] = t('PHONE_NUMBER_IS_NOT_VALID')
    }
  }

  setErrors(errors)

  return formIsValid
}

interface MapDispatchToProps {
  loginWithSms: (phoneNumber: string) => void
}

interface MapStateToProps {
  isFetching: boolean
  isExpired: string
}

const mapStateToProps = (state: State): MapStateToProps => ({
  isFetching: state.auth.login.sms.is_fetching,
  isExpired: state.auth.login.sms.error,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  loginWithSms: (phoneNumber: string) => dispatch(loginWithSms(phoneNumber)),
})

const enhancer = compose<React.FunctionComponent>(
  withTranslation(),
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(LoginWithPhone)
