// fixme: This methods are harcoded now becuase they where hardcoded in the backend
//  (and no api is now provided from backend side) to fix this it needs to create an end piont in the backend.
export default (state = initalState) => {
  return state
}

export const initalState: State = {
  data: [
    'ftn-aktia-auth',
    'ftn-alandsbanken-auth',
    'ftn-danskebank-auth',
    'ftn-handelsbanken-auth',
    'ftn-nordea-auth',
    'ftn-omasp-auth',
    'ftn-op-auth',
    'ftn-pop-auth',
    'ftn-sp-auth',
    'ftn-spankki-auth',
    'mobiilivarmenne-ftn',
  ],
}

export interface State {
  data: string[]
}
