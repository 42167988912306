import { ThunkAction } from 'redux-thunk'
import { Dispatch } from 'redux'
import { setBrokerLoanApplicationId } from './creators'
import { brokerLoanApplicationId } from '../../../../utils/broker'

/* TODO Unit Test */
export const getBrokerLoanApplication = (): ThunkAction<
  any,
  any,
  any,
  any
> => async (dispatch: Dispatch) => {
  const data = brokerLoanApplicationId()
  const blaId = data?.blaId
  const fromBroker = data?.fromBroker
  dispatch(setBrokerLoanApplicationId(blaId, fromBroker))
}
