import React, { useState } from 'react'
import { connect } from 'react-redux'
import { State } from '../../../../store'
import { Col, Row, Container } from 'reactstrap'
import { Loan as LegacyLoan } from '../../../../models/Legacy/Borrower/Loan'
import { setSpaceForReferenceNumber } from '../../../../utils'
import { Loan } from '../../../../models/Auth/ProfileData'
import browserHistory from 'browserHistory'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import ic_bar_char_decrease from 'assets/images/ic_bar_char_decrease.svg'
import ic_bar_char_fixed from 'assets/images/ic_bar_char_fixed.svg'
import LoanStatus from '../LoanStatus'
import LoanLeft from '../LoanLeft'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'

interface IProps extends MappedStateModel {}

const LoanSwitch: React.FunctionComponent<IProps> = (props) => {
  const { legacyLoans, loans, isVisible } = props
  const { t } = useTranslation()
  const location = useLocation()
  const [show, setShow] = useState(true)

  const defaultLoanId = location.pathname.split('/').pop()

  if (!isVisible) return null

  const toggle = () => setShow(!show)
  const openLegacyLoanPopUp = () => {
    setShow(!show)
  }

  return (
    <>
      {/* popUp appear for legacy loans */}
      <Toast isOpen={!show} className="legacy_loan__container">
        <ToastHeader
          className="legacy_loan__header"
          toggle={toggle}
        ></ToastHeader>
        <ToastBody className="legacy_loan__body">
          <Col className="center p-10">
            <Row className="center p-10">
              <span>{t('POPUP_LINK_TO_LEGACY_LOAN_TEXT')}</span>
            </Row>
            <Row className="center p-10">
              <a href="https://old2.fixura.fi">
                &nbsp;{t('POPUP_LINK_TO_LEGACY_LOAN')}
              </a>
            </Row>
          </Col>
        </ToastBody>
      </Toast>
      {loans &&
        loans.map((loan) => {
          return (
            <Container className="add-funds__wrapper px-25" key={loan.id}>
              <Row className="center p-10">
                <Col
                  className="download-item switch-box"
                  onClick={() =>
                    // @ts-ignore
                    defaultLoanId !== loan.id &&
                    browserHistory.push(
                      `/dashboard/borrower/fair-credit-loan/${loan.id}`
                    )
                  }
                  md={6}
                >
                  <div className="download-item__wrapper pointer">
                    <div>
                      <strong className="download-item--title">
                        <img
                          className="img_bar_chart"
                          src={ic_bar_char_decrease}
                          alt=""
                        />
                        <span>{`${t('FAIR_CREDIT_LOAN')} (${loan.id})`}</span>
                      </strong>
                    </div>
                    <LoanLeft loanMambuId={loan.mambu_loan_id} />
                    <LoanStatus
                      loanId={loan.id}
                      loanMambuId={loan.mambu_loan_id}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          )
        })}
      {legacyLoans.map((loan) => {
        return (
          <Container className="add-funds__wrapper px-25" key={loan.Reference}>
            <Row className="center p-10">
              <Col
                className="download-item switch-box"
                onClick={openLegacyLoanPopUp}
                md={6}
              >
                <div className="download-item__wrapper pointer">
                  <div>
                    <strong className="download-item--title">
                      <img
                        className="img_bar_chart"
                        src={ic_bar_char_fixed}
                        alt={t('LOAN')}
                      />
                      <span>
                        {`${t('LOAN')} (${setSpaceForReferenceNumber(
                          loan.Reference
                        )})`}
                      </span>
                    </strong>
                  </div>
                  <LoanLeft legacyLoanId={loan.Reference} />
                  <LoanStatus legacyLoanId={loan.Reference} />
                </div>
              </Col>
            </Row>
          </Container>
        )
      })}
    </>
  )
}

interface MappedStateModel {
  legacyLoans: LegacyLoan[]
  loans: Loan[]
  isVisible: boolean
}

const mapStateToProps = (state: State): MappedStateModel => {
  const legacyLoans = state.legacy.borrower.loan.data
  const loans = state.user.profile.data.loans
  return {
    legacyLoans,
    loans: loans,
    isVisible:
      legacyLoans.length > 1 ||
      (legacyLoans.length + loans.length > 0 &&
        state.user.profile.data.id !== 0),
  }
}

export default connect(mapStateToProps)(LoanSwitch)
