import React from 'react'
import { Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const LinkLegacyLoan = () => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <Row className="center p-10">
          <Col className="text-center">
            <span>
              {t('LINK_TO_LEGACY_LOAN_TEXT')}
              <a href="https://old2.fixura.fi">
                &nbsp;{t('LINK_TO_LEGACY_LOAN')}
              </a>
              .
            </span>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default LinkLegacyLoan
