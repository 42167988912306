import {
  LOAN_CALCULATION_FAILURE,
  LOAN_CALCULATION_REQUEST,
  LOAN_CALCULATION_SUCCESS,
} from './types'
import LoanCalculatorResponse from '../../../../../models/Borrower/LoanCalculatorResponse'

export const createLoanCalculatorRequestAction = () => ({
  type: LOAN_CALCULATION_REQUEST,
})
export const createLoanCalculatorSuccessAction = (
  data: LoanCalculatorResponse
) => ({
  data,
  type: LOAN_CALCULATION_SUCCESS,
})
export const createLoanCalculatorFailureAction = (error: any) => ({
  error,
  type: LOAN_CALCULATION_FAILURE,
})
