import {
  createWithdrawFailureAction,
  createWithdrawRequestAction,
  createWithdrawSuccessAction,
} from './creators'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import InvestorAPI from '../../../../../../api/user/InvestorAPI'
import getInvestorTransactions from '../../transactions/actions/getInvestorTransactions'
import getInvestorKpi from '../../kpi/actions/getInvestorKpi'
import { State } from '../../../../../index'
import getWithdrawStatus from '../../withdraw/actions/getWithdrawStatus'

const postWithdraw = (
  amount,
  depositId
): ThunkAction<any, any, any, any> => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => State
) => {
  dispatch(createWithdrawRequestAction())
  const freeCapitalBeforeWithdraw = getState().user?.investor.dashboard.kpi.data
    ?.free_capital

  try {
    const investorAPI = new InvestorAPI()
    await investorAPI.postWithdraw(amount, depositId)
    dispatch(createWithdrawSuccessAction(amount))

    if (parseInt(amount) > parseInt(freeCapitalBeforeWithdraw)) {
      dispatch(getWithdrawStatus(depositId))
    }

    const { transactions } = getState().user.investor.dashboard
    if (transactions.dates) {
      dispatch(
        getInvestorTransactions(
          transactions.dates,
          transactions.type,
          depositId
        )
      )
    }

    const { kpi } = getState().user.investor.dashboard
    if (kpi.dates) {
      dispatch(getInvestorKpi(kpi.dates, depositId))

      // After 5 seconds refetch KPI's
      setTimeout(() => {
        dispatch(getInvestorKpi(kpi.dates, depositId))
      }, 5000)

      // Check that request amount is same as free capital, and refetch KPI's after 20 seconds
      setTimeout(() => {
        const freeCapitalAfterWithdraw = getState().user.investor.dashboard.kpi
          .data.free_capital
        if (
          parseInt(freeCapitalBeforeWithdraw) ===
          parseInt(freeCapitalAfterWithdraw)
        ) {
          dispatch(getInvestorKpi(kpi.dates, depositId))
        }
      }, 20000)
    }
  } catch (error) {
    dispatch(createWithdrawFailureAction(error))
  }
}

export default postWithdraw
