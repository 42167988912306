import React from 'react'
import { State } from '../../../store'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import CheckBoxCCIS from './CheckBoxCCIS'
import { Button } from 'reactstrap'
import { ThunkDispatch } from 'redux-thunk'
import updateCCIS from '../../../store/user/borrower/loan_application/actions/updateCCIS'
import Indicator from '../../shared/Indicator'
import Steps from '../../Auth/Register/Steps'
import ic_check_circle from 'assets/images/ic_check_circle.svg'

interface Props extends MappeedState, MappeedDispatch {}

const CCIS = (props: Props) => {
  const { t } = useTranslation()
  const { isFetching, updateCCIS } = props

  return (
    <section id="container" className="container borrower-onboarding">
      <Indicator isVisible={isFetching} />
      <Steps currentStepIndex={2} />
      <div className="responsive-ccis">
        <h1 className="font-50 step-big-title">{t('CCIS_CONSENT_HEADLINE')}</h1>
        <div className="ccis-step-big-title">
          <div className="p-20">
            <span className="ccis_bulltet badge ccis_badge-primary badge-pill">
              1
            </span>
            <p className="receive-offers__title ml-75 font-20-bold">
              {t('VERIFY_INFO_FIRST_LINE')}
            </p>
          </div>
          <div className="ml-68 p-20">
            <img
              src={ic_check_circle}
              alt={t('VERIFY_INFO_SECOND_LINE')}
              className="footer-icon circle-fill-ccis"
            />
            <p className="receive-offers__title ml-50">
              {t('VERIFY_INFO_SECOND_LINE')}
            </p>
          </div>
          <div className="p-20">
            <span className="ccis_bulltet badge ccis_badge-primary badge-pill">
              2
            </span>
            <p className="receive-offers__title ml-75 pt-4 font-20-bold">
              {t('VERIFY_INFO_THIRD_LINE')}
            </p>
          </div>
        </div>
        <CheckBoxCCIS />
        <div className="step-big-title">
          <Button onClick={updateCCIS} className="min-w-10">
            {t('CONTINUE')}
          </Button>
        </div>
      </div>
    </section>
  )
}

interface MappeedState {
  isFetching: boolean
}
interface MappeedDispatch {
  updateCCIS: () => void
}

const mapStateToProps = (state: State): MappeedState => ({
  isFetching: state.user.borrower.loan_application.is_fetching,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappeedDispatch => ({
  updateCCIS: () => dispatch(updateCCIS()),
})

export default connect<MappeedState, MappeedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(CCIS)
