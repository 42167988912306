import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from './actions/types'
import ProfileData from '../../../models/Auth/ProfileData'

export interface State {
  is_fetching: boolean
  data: ProfileData
  error: any
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case GET_PROFILE_REQUEST:
      return { ...state, is_fetching: true }
    case LOGOUT_SUCCESS:
      return initialState
    case GET_PROFILE_SUCCESS:
      const nationality = action.data.nationality ?? 'FI'
      return {
        ...initialState,
        data: { ...action.data, nationality },
        is_fetching: false,
        error: null,
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        is_fetching: false,
        error: null,
        data: { ...state.data, ...action.data },
      }
    case GET_PROFILE_FAILURE:
    case LOGOUT_FAILURE:
      return { ...initialState, error: action.error, is_fetching: false }
    case UPDATE_PROFILE_FAILURE:
      return { ...state, is_fetching: false, error: action.error }
    default:
      return state
  }
}

export const initialState: State = {
  is_fetching: false,
  data: {
    id: 0,
    legacy_users: [],
    email: '',
    email_verified_at: '',
    created_at: '',
    updated_at: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    birth_date: '',
    mobile_phone: '',
    home_phone: '',
    ssn: '',
    language: localStorage.getItem('i18nextLng') || 'en',
    mambu_id: '',
    mobile_verified_at: '',
    is_checked_offers: false,
    is_checked_email_offers: false,
    is_checked_sms_offers: false,
    deposits: [],
    loans: [],
    address: undefined,
    nationality: undefined,
    bank_accounts: [],
    user_documents: [],
    companies: [],
    force_aml_fill_in: false,
    can_test: false,
    is_foreigner: 0,
    verification_error_code: 0,
  },
  error: null,
}
