import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const LinkForeignInvestor = () => {
  const { t } = useTranslation()

  return (
    <div className="center-text pt-10 px-15">
      <Link to="/register/foreign-investor/provide-details">
        <span>{t('NOT_IN_FINLAND')}</span>
      </Link>
    </div>
  )
}

export default LinkForeignInvestor
