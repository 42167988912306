import React from 'react'

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <div className="contracts-shared">
      <h3>Fixuran tietosuojaseloste</h3>
      <p>
        Tästä tietosuojaselosteesta voit lukea miten keräämme ja käytämme
        henkilötietojasi. Kerromme myös, mitä oikeuksia sinulla on ja miten voit
        käyttää niitä. Olemme sitoutuneet käyttäjiemme yksityisyyden
        suojaamiseen tietosuojalainsäädännön sekä muun soveltuvan lainsäädännön
        mukaisesti. On tärkeää, että luet ja ymmärrät tietosuojaselosteemme
        ennen kuin käytät palveluitamme.
      </p>

      <h3>Keskeiset käsitteet</h3>
      <p>
        Henkilötiedoilla tarkoitetaan kaikkia tunnistettuun tai tunnistettavissa
        olevaan luonnolliseen, elävään henkilöön liittyviä tietoja.
        Henkilötietoja ovat esimerkiksi nimi, osoite ja puhelinnumero. Myös
        esimerkiksi tiedot IP- osoitteesta kotisivuillamme vieraillessasi,
        voivat olla henkilötietoja.
      </p>
      <p>
        Henkilötietojen käsittelyllä tarkoitetaan kaikkea henkilötietojen
        käsittelyä jota on esimerkiksi henkilötietojen kerääminen, analysointi,
        muuttaminen ja säilyttäminen.
      </p>
      <p>
        Rekisterinpitäjällä tarkoitetaan sitä tahoa, joka määrittelee
        henkilötietojen käsittelyn tarkoitukset ja keinot. Rekisterinpitäjä myös
        vastaa siitä, että henkilötietoja käsitellään sovellettavan
        lainsäädännön mukaisesti.
      </p>
      <p>
        Rekisteröidyllä tarkoitetaan käyttäjää joka on rekisteröitynyt Fixuran
        palveluiden käyttäjäksi.
      </p>

      <h3>1. Rekisterin pitäjä</h3>
      <p>
        Fixura Ab Oy <br />
        Y-tunnus 2246639-7
        <br />
        Hovioikeudenpuistikko 15 C 29, 65100 Vaasa
        <br />
        Puh. +358(0)20 7344 530
        <br />
        Sähköposti: <a href="mailto:info@fixura.com">info@fixura.com</a>
      </p>

      <h3>2. Yhteydenotot rekisteriä koskevissa asioissa</h3>
      <p>
        Kyselyt ja tarkastuspyynnöt omia tietoja koskien tulee lähettää
        osoitteeseen <a href="mailto:info@fixura.com">info@fixura.com</a>
        <br />
      </p>

      <h3>3. Rekisterin nimi</h3>
      <p>Fixura Ab Oy:n asiakasrekisteri</p>

      <h3>4. Henkilötietojen käsittelyn tarkoitus</h3>
      <p>
        Henkilötietojen käsittelyn tarkoituksena on Fixura Ab Oy:n
        asiakasrekisteriin liittyvien tehtävien ja palveluiden hoitaminen ja
        kehittäminen. Henkilötietoja tarvitaan laina- ja sijoitussopimusten
        täytäntöönpanoon sekä asiakassuhteiden ylläpitoon. Myös mm.
        lainaluokituksen tekeminen, yhteystietojen hallinta, suoramarkkinointi
        ja palveluiden kohdentaminen asiakkaille, riskienhallinta pitäen
        sisällään mm. rahanpesun vastaisen toiminnan, lakiin perustuvien ja
        viranomaisten määräysten ja ohjeiden mukaisten säilytys-, raportointi-
        ja kyselyvelvoitteiden hoitaminen ovat riippuvaisia henkilötietojen
        käsittelystä. Henkilötietoja käsitellään vain lain sallimissa ja
        velvoittamissa puitteissa asiakassuhteiden hoitamiseen, hallinnoimiseen,
        kehittämiseen, analysointiin, tilastointiin ja tietopalveluiden
        tuottamiseen ja tarjoamiseen.
      </p>
      <p>
        Fixura Ab Oy:llä ja sen kanssa kulloinkin samaan konserniin kuuluvilla
        yhtiöillä on myös oikeus käyttää ja luovuttaa rekisterissä olevia
        tietoja perusteltuja käyttötarkoituksia varten kuten markkinointiin,
        henkilötietolain mukaisesti. Rekisteröityessään henkilö voi hyväksyä
        tietojensa käytön markkinointitarkoituksiin.
      </p>

      <h3>5. Rekisterin tietosisältö</h3>
      <p>
        Rekisterissä voidaan säilyttää tietoja alla olevista rekisteröityjen
        ryhmistä:
      </p>
      <ul>
        <li>
          Rekisteröidyllä on tai on ollut asiakassuhde, sen perustamiseksi tehty
          hakemus, asiakkuuden rekisteröinti tai muu henkilökohtainen
          yhteydenotto rekisterinpitäjään.
        </li>
        <li>
          Rekisteröidyllä on tai on ollut asiakassuhteeseen liittyvä osallisuus,
          velvoite tai muu oikeus sopimukseen, palveluun tai toimeksiantoon
          rekisterinpitäjään.
        </li>
        <li>
          Tietojen rekisteröinti johtuu lakiin perustuvasta velvoitteesta.
        </li>
      </ul>

      <h3>Henkilöön liittyvät perustiedot, kuten</h3>
      <ul>
        <li>
          Nimi, osoitteet, postinumerot, postitoimipaikat, kotikunta,
          puhelinnumerot, pankkitiedot ja sähköpostiosoitteet
        </li>
        <li>Asiakassuhteen alkamis- ja päättymispäivä</li>
        <li>Syntymäaika ja henkilötunnus/y-tunnus</li>
        <li>Kieli, kansalaisuus</li>
        <li>Verotusmaat</li>
        <li>
          Tiedot asiakkaan taloudellisesta asemasta (esim. koulutus, ammatti,
          tulotiedot)
        </li>
        <li>Suoramarkkinoitikiellot ja suostumukset</li>
        <li>IP-osoite</li>
        <li>
          Asiointitiedot lainanottajat (käyttäjätunnus, hakemukset, sopimukset,
          laskutus ja perintä, maksuhäiriöt)
        </li>
        <li>
          Asiointitiedot sijoittajat (käyttäjätunnus, sopimukset,
          sijoittajatiedot esim. sijoittajakokemus, yrityskäyttäjän perustiedot
          kuten yrityksen nimi, y-tunnus, yhteyshenkilö)
        </li>
        <li>
          Lakiin perustavat velvoitteet ja sidonnaisuudet (esim. poliittisesti
          vaikutusvaltainen henkilö)
        </li>
      </ul>
      <p>Asiakkaan nimenomaisella suostumuksella annetut tiedot:</p>
      <ul>
        <li>Pankkitilitiedot</li>
        <li>
          Suomen Asiakastieto Oy:n kuluttajaluottojen kyselyjärjestelmän tiedot
        </li>
      </ul>
      <p>
        Asiakasrekisteriin tallennetaan asiakkaan nimenomaisella suostumuksella
        luottohakemuksen käsittelyn yhteydessä asiakkaan pankkitilin transaktiot
        viimeisen 12 kuukauden ajalta niiden luotonmyöntämisen yhteydessä
        tehtävää analysointia varten.
      </p>
      <p>
        Asiakasrekisteriin tallennetaan asiakkaan nimenomaisella suostumuksella
        luottohakemuksen käsittelyn yhteydessä toisilta luotonantajilta saatuja
        tietoja aikaisemmista luotoista. Tietoja kerätään Suomen Asiakastieto
        Oy:n ylläpitämän kyselyjärjestelmän avulla.
      </p>

      <h3>6. Henkilötietojen säilytysaika</h3>
      <p>
        Rekisteröidystä kerättyjä tietoja säilytetään rekisterinpitäjän
        asiakasrekisterissä lain vaatimusten mukaisesti, jolloin tietojen
        säilytysajat vaihtelevat niiden luonteesta riippuen.
      </p>
      <p>
        Pääosin tietoja säilytetään viisi vuotta siitä päivästä lähtien, kun
        rekisteröidyn laina tai sijoitukset on kokonaan takaisinmaksettu.
        Käyttäjätili voidaan säilytysajan aikana rekisteröidyn nimenomaisesta
        pyynnöstä inaktivoida, jolloin tiliä ei voi enää käyttää. Tästä
        huolimatta erinäiset tiedot kuten tilitapahtumat säilytetään
        kirjapitosäädösten mukaan kuitenkin vähintään kymmenen vuotta.
      </p>
      <p>
        Mikäli rekisteröidyllä ei ole aktiivista lainaa tai sijoituksia,
        säilytetään tiedot kaksi vuotta viimeisestä rekisteröinnistä,
        sisäänkirjautumisesta, hakemuksesta tai muusta toiminnosta lukien.
      </p>

      <h3>7. Säännönmukaiset tietolähteet</h3>
      <p>
        Rekisteriin kerätään tietoja käyttäjästä rekisteröitymisen yhteydessä
        sekä asiakassuhteen aikana, esimerkiksi silloin kun henkilö
        rekisteröityy palveluun tai päivittää tietojaan asiakassuhteen aikana.
        Henkilötietoja voidaan kerätä ja/tai vahvistaa seuraavista lähteistä:
      </p>
      <ul>
        <li>Asiakkaalta itseltään rekisteröinnin yhteydessä tai myöhemmin</li>
        <li>Bisnode Finland Oy:ltä (luottotiedot)</li>
        <li>
          Suomen Asiakastieto Oy:ltä (luottotiedot, tilitiedot, lainatiedot)
        </li>
        <li>
          Väestötietorekisteri ja muut viranomaisten ylläpitämät rekisterit (mm.
          osoitetiedot, henkilötunnuksen vahvistaminen)
        </li>
        <li>
          Lainavertailu- ja välittäjäsivustot (asiakastiedot lainahakemuksesta)
        </li>
        <li>Signicat Ab (tunnistautuminen)</li>
        <li>Onfido (tunnistautuminen)</li>
        <li>Tink (tilitiedot)</li>
      </ul>

      <h3>8. Tietojen säännönmukaiset luovutukset</h3>
      <p>
        Tietoja ei lähtökohtaisesti luovuteta ulkopuolisille, lukuun ottamatta
        niitä yrityksiä, joiden kanssa teemme yhteistyötä kuten
        luottotietoyritykset. Tietoja voidaan tarvittaessa luovuttaa myös
        perintätoimistolle ja viranomaisille.
      </p>
      <p>
        Asiakasrekisterin tietoja luotoista ja niiden hoitamisesta voidaan
        luovuttaa toisille luotonantajille asiakkaan antaman toimeksiannon ja
        suostumuksen perusteella. Tietoja luovutetaan Suomen Asiakastieto Oy:n
        ylläpitämän kyselyjärjestelmän avulla.
      </p>
      <p>
        Tietoja voidaan luovuttaa markkinointi- tai tutkimustarkoituksiin, jos
        asiakas on antanut siihen suostumuksensa. Markkinointi- ja
        tutkimustarkoituksiin annettu suostumus on aina mahdollista perua.
      </p>
      <p>
        Tietojen säännönmukaiset luovutukset kohdistuvat yrityksemme omaan
        markkinointiin, jossa tarvittavia tietoja ovat sähköpostitiedot sekä
        etu- ja sukunimitiedot.
      </p>

      <h3>9. Tietojen siirto EU:n tai ETA:n ulkopuolelle</h3>
      <p>
        Emme pääsääntöisesti siirrä henkilötietoja EU:n tai ETA:n ulkopuolelle.
        Silloin kun tietojen siirtäminen EU:n tai ETA:n ulkopuolelle on palvelun
        toteuttamisen vuoksi tarpeellista, huolehdimme henkilötietojen suojan
        riittävästä tasosta muun muassa sopimalla henkilötietojen
        luottamuksellisuuteen ja käsittelyyn liittyvistä asioista lainsäädännön
        edellyttämällä tavalla, esimerkiksi Euroopan Komission hyväksymiä
        mallisopimuslausekkeita käyttäen, ja muuten siten, että henkilötietojen
        käsittely tapahtuu tämän tietosuojakäytännön mukaisesti.
      </p>

      <h3>10. Profilointi ja automaattinen päätöksenteko</h3>
      <p>
        Henkilötietojen käsittelyyn sovelletaan sekä automaattista
        päätöksentekoa, että profilointia. Tämä on välttämätöntä
        luottopäätöksenteon kannalta.
      </p>
      <p>
        Profiloinnilla tarkoitetaan esimerkiksi asiakkaan taloudellisen
        tilanteeseen, kiinnostuksen kohteisiin, luotettavuuteen,
        käyttäytymiseen, sijaintiin tai liikkeisiin liittyvien piirteiden
        analysointia tai ennakointia.
      </p>
      <p>
        Automaattinen päätöksenteko tarkoittaa sitä, että päätös lainan
        saamisesta tehdään lainahakemuksessa olevien tietojen perusteella
        automaattisesti ilman manuaalista käsittelyä. Lainapäätös pohjautuu
        profilointiin, jossa analysoidaan lainanhakijan henkilökohtaisia
        ominaisuuksia sekä eri lähteistä saatuihin tietoihin kuten
        luottoluokitukseen, maksuhäiriömerkintöihin, velkoihin, tuloihin,
        menoihin ja muihin vastaaviin tietoihin.
      </p>
      <p>
        Rekisteröidyllä on tietyissä tapauksissa oikeus saattaa kielteinen
        luottopäätös luonnollisen henkilön käsittelyyn. Tämä oikeus ei
        kuitenkaan koske luottopäätöksiä, jotka on hylätty luottotietomerkinnän
        tai jonkin muun siihen rinnastettavan syyn vuoksi.
      </p>

      <h3>11. Rekisterin suojauksen periaatteet</h3>
      <p>
        Asiakastiedot, jotka ovat manuaalisessa muodossa, säilytetään lukitussa
        ja vartioidussa toimitilassa. Asiakasrekisterin tiedot ovat
        tietokannassa, joka on suojattu palomuurin, käyttäjätunnusten sekä
        muiden teknisten keinojen avulla. Tietojen käyttöoikeudet ovat vain
        Fixura Ab Oy:n henkilökunnalla tai valtuutetuilla yhteistyökumppaneilla.
        Fixura Ab Oy vaatii, että henkilöstä ja yhteistyökumppanit noudattavat
        salassapitovelvollisuutta.
      </p>

      <h3>12. Tarkastusoikeus</h3>
      <p>
        Jokaisella rekisteröityneellä henkilöllä on lainmukainen oikeus
        tarkastaa häntä koskevat henkilörekisteriin merkityt tiedot. Pyyntö
        tulee tehdä kirjallisena osoitteeseen{' '}
        <a href="mailto:info@fixura.com">info@fixura.com</a>.
      </p>

      <h3>13. Oikeus vaatia tiedon korjaamista</h3>
      <p>
        Rekisteröidyllä on oikeus vaatia virheellisen tiedon oikaisemista tai
        poistamista. Osan antamistaan tiedoista käyttäjä voi itse muuttaa oman
        profiilinsa kautta. Siltä osin kuin rekisteröitynyt käyttäjä voi itse
        muuttaa virheellistä tietoa, tulee hänen viipymättä oma-aloitteisesti
        muuttaa tietojaan. Mikäli käyttäjä ei pysty muuttamaan tietojaan, tulee
        hänen ottaa kirjallisesti yhteyttä Fixuran asiakaspalveluun muutoksen
        tekemiseksi. Rekisteröity on myös omalta osaltaan velvollinen pitämään
        mm. yhteystietonsa ajantasalla.
      </p>

      <h3>14. Oikeus tietojen poistamiseen sekä käsittelyn rajoittamiseen</h3>
      <p>
        Rekisteröidyn henkilötietojen käsittely perustuu sopimukseen (yleinen
        tietosuoja-asetus, artikla 6 kohta b). Tällaisessa tilanteessa
        henkilöllä ei lähtökohtaisesti ole oikeutta omien tietojensa
        poistamiseen eikä niiden käsittelyn rajoittamiseen.
      </p>
      <p>
        Silloin kun rekisteröitynyt on antanut suostumuksensa
        suoramarkkinointiin, voi hän myös perua suostumuksensa tietojensa
        käyttöön suoramarkkinointia varten. Suostumuksen suoramarkkinointiin voi
        antaa tai peruuttaa kirjautumalla omaan profiilinsa tai ottamalla
        kirjallisesti yhteyttä Fixuran asiakaspalveluun.
      </p>

      <h3>15. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h3>
      <p>
        Rekisteröidyllä on oikeus tehdä valitus Tietosuojaviranomaiselle, mikäli
        hän katsoo, että Fixura ei rekisterinpitäjänä ole noudattanut tämän
        tietosuojaselosteen tai voimassa olevan lainsäädännön mukaista
        käytäntöä.
      </p>
    </div>
  )
}

export default PrivacyPolicy
