import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getLegacyLoans } from '../../../store/legacy/borrower/loan/actions/getLegacyLoans'
import { default as LoanMainInfo } from './LoanMainInfo/Legacy'
import { default as PaymentPlan } from './PaymentPlan/Legacy'
import { default as NextInstallment } from './NextInstallment/Legacy'
import LoanSetting from './LoanSetting'
import CustomerService from '../../shared/CustomerService'
import Indicator from '../../shared/Indicator'
import { State } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import LegacyGreet from './LegacyGreet'
import BackButton from '../../shared/BackButton'
import useHashLink from 'hooks/useHashLinks'
import { Loan as LegacyLoan } from '../../../models/Legacy/Borrower/Loan'
import { useParams } from 'react-router-dom'
import { LegacyUser } from 'models/Auth/ProfileData'
import getPersonalDetails from 'store/legacy/user/action/getPersonalDetails'
import { useLocation, useHistory } from 'react-router'
import DebtCollection from './DebtCollection'
import LinkLegacyLoan from './LinkLegacyLoan'

interface Props extends MapStateToProps, MapDispatchToProps {
  isNormalUser: boolean
}

const Presentation = (props: Props) => {
  const {
    getLegacyLoans,
    isNormalUser,
    isFetching,
    legacyLoans,
    legacyUsers,
    getPersonalDetails,
  } = props

  const { hash } = useLocation()
  useHashLink(isFetching)
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    getPersonalDetails()
  }, [getPersonalDetails])

  useEffect(() => {
    if (legacyUsers.length) {
      getLegacyLoans()
    }
  }, [getLegacyLoans, legacyUsers])

  useEffect(() => {
    if (legacyLoans.length && !id) {
      history.replace(
        `/restricted/borrower/fixed-rate-loan/${legacyLoans[0].Reference}${hash}`
      )
    }
  }, [hash, history, id, legacyLoans])
  const { pathname } = useLocation()
  const loanDetails = pathname.includes('loan')

  return (
    <div key={id}>
      <Indicator isVisible={isFetching} />
      {isNormalUser && <BackButton />}
      {!isNormalUser && <LegacyGreet />}
      <LinkLegacyLoan />
      {/* TODO due installment section  */}
      <LoanSetting />
      {/* @ts-ignore */}
      <DebtCollection />
      <LoanMainInfo />
      {/* @ts-ignore */}
      <NextInstallment />
      <PaymentPlan />
      {!loanDetails && <CustomerService />}
    </div>
  )
}

interface MapStateToProps {
  isFetching: boolean
  legacyLoans: LegacyLoan[]
  legacyUsers: LegacyUser[]
}

interface MapDispatchToProps {
  getLegacyLoans: () => void
  getPersonalDetails: () => {}
}

const mapStateToProps = (state: State): MapStateToProps => ({
  isFetching:
    state.legacy.borrower.loan.is_fetching || state.legacy.user.is_fetching,
  legacyLoans: state.legacy.borrower.loan.data,
  legacyUsers: state.legacy.user.legacy_users,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MapDispatchToProps => ({
  getLegacyLoans: () => dispatch(getLegacyLoans()),
  getPersonalDetails: () => dispatch(getPersonalDetails()),
})

const enhancer = compose(
  connect<MapStateToProps, MapDispatchToProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )
)

export default enhancer(Presentation)
