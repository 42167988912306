import React from 'react'
import InvoiceTypeEnum from './InvoiceType.enum'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import ic_flat from 'assets/images/ic_flat.png'
import ic_late from 'assets/images/ic_late.png'
import ic_pdf_white from 'assets/images/ic_pdf_white.svg'

interface Props extends WithTranslation {
  invoiceStatus: InvoiceTypeEnum
}

const InvoiceStatus = (props: Props) => {
  const { invoiceStatus, t } = props
  switch (invoiceStatus) {
    case InvoiceTypeEnum.OVERPAID:
    case InvoiceTypeEnum.PARTIALLY_PAID:
    case InvoiceTypeEnum.PAID:
      return (
        <>
          <img src={ic_flat} alt="paid on time" />
          <span className="d-sm-inline-block">{t(invoiceStatus)}</span>
        </>
      )
    case InvoiceTypeEnum.CANCELED:
    case InvoiceTypeEnum.PAID_LATE:
      return (
        <>
          <img src={ic_late} alt="paid late" />
          <span className="d-sm-inline-block">{t(invoiceStatus)}</span>
        </>
      )
    case InvoiceTypeEnum.INVOICED:
      return (
        <>
          <img src={ic_pdf_white} alt="Invoiced" />
          <span className="font-13">{t('View')}</span>
        </>
      )
    default:
      return <>{t(invoiceStatus)}</>
  }
}

const enhancer = compose(withTranslation())

export default enhancer(InvoiceStatus)
